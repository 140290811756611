import React, { useEffect } from "react";
import Slider from "react-slick"; // Ensure you have react-slick installed

import Head from "../header";
import Footer from "../footer";

function AnimalBedding(){
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };

    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/productt-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/productt-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/productt-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/productt-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/productt-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/productt-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/productt-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/productt-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/productt-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Animal Bedding</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Our coir-based animal bedding is an eco-friendly, comfortable bedding material suitable for a wide range of animals. It provides superior moisture absorption and odor control, creating a hygienic environment for pets and livestock.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                   
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>High Water Retention: Holds up to 8-10 times its weight in water, helping plants maintain consistent moisture levels.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Optimal pH Balance: Neutral to slightly acidic pH (typically between 5.5-6.5), making it suitable for a wide range of plants.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Sustainable: A renewable resource that decomposes slowly, reducing environmental impact compared to traditional peat moss.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Natural Aeration: Prevents soil compaction, promoting healthier root development and nutrient uptake.

</li>
                                
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                        	 Our coir-based animal bedding is soft, natural, and offers high absorbency, creating a comfortable, hygienic environment for animals.
		Benefits: Coir bedding is absorbent and dust-free, reducing respiratory issues for animals. It is naturally antibacterial, providing an additional level of hygiene.
		Applications: Suitable for use with pets like rabbits, guinea pigs, and hamsters, as well as for larger livestock such as horses. The bedding can also be composted after use, adding organic matter back to the earth.

                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Packaged in various sizes for retail customers; easily incorporated into potting mixes and gardening soil.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Used as a soil amendment in garden beds, improving moisture retention and preventing soil erosion.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Marketed as a natural, sustainable option for pet and livestock bedding.</li>
                            <li><span className="icon-check-mark" /><h6>Home Use:</h6> Suitable for pets such as rabbits, guinea pigs, and reptiles, as well as composting after use.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Available in various quantities tailored to individual and bulk needs.</p>
                        <h3 className="product-details__description__title">How to Use Coir Animal Bedding:</h3>
                        <p className="product-details__description__text">
                            Garden Grow’s coir animal bedding is an eco-friendly and highly absorbent solution for small animals. Here’s how to use it for pets or livestock:
                        </p>
                        <ul>
                            <li><h6><strong>For Small Animals (Rabbits, Guinea Pigs, etc.):</strong></h6> Spread an even layer of coir bedding in the bottom of your pet’s cage or hutch. Ensure the bedding is deep enough to absorb waste and moisture. Change the bedding regularly to maintain cleanliness and prevent odors.</li>
                            <li><h6><strong>For Poultry (Chickens, Ducks, etc.):</strong></h6> Spread coir bedding in your poultry coop or nesting boxes. Coir’s high absorbency helps control moisture, while its rough texture provides comfort for the animals.</li>
                            <li><h6><strong>For Livestock:</strong></h6> Coir bedding can be used in stalls and barns for larger animals. The bedding helps maintain a dry, clean, and comfortable environment. Replace the bedding as needed to ensure a healthy living area for the animals.</li>
                            <li><h6><strong>Composting:</strong></h6> Coir bedding can be composted, making it an environmentally friendly choice. It adds organic matter to your compost, improving its texture and nutrient content.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}   
export default AnimalBedding;