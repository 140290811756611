import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Signup() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileno: '',
        password: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedFormData = {
            ...formData,
            mobileno: Number(formData.mobileno)
        };
        try {
            const response = await axios.post('https://gardengrowbackend.onrender.com/user/createUser', updatedFormData);
            setMessage("User created successfully!");
            setFormData({ firstName: '', lastName: '', email: '', mobileno: '', password: '', confirmPassword: '' });
            console.log(response.data);
        } catch (error) {
            setMessage("There was an error creating the user!");
            console.error("There was an error creating the user!", error);
        }
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    {/* Billing Address */}
                    <div className="col-xl-6 col-lg-6">
                        <div className="checkout-page__billing-address">
                            <h2 className="checkout-page__billing-address__title">Create User</h2>
                            {message && <div className="alert">{message}</div>}
                            <form className="checkout-page__form" onSubmit={handleSubmit}>
                                {/* First Name */}
                                <div className="checkout-page__input-box">
                                    <input type="text" name="firstName" value={formData.firstName} placeholder="First Name *" required onChange={handleChange} />
                                </div>
                                {/* Last Name */}
                                <div className="checkout-page__input-box">
                                    <input type="text" name="lastName" value={formData.lastName} placeholder="Last Name *" required onChange={handleChange} />
                                </div>
                                {/* Email */}
                                <div className="checkout-page__input-box">
                                    <input type="email" name="email" value={formData.email} placeholder="Email *" required onChange={handleChange} />
                                </div>
                                {/* Mobile Number */}
                                <div className="checkout-page__input-box">
                                    <input type="tel" name="mobileno" value={formData.mobileno} required placeholder="Mobile number *" onChange={handleChange} />
                                </div>
                                {/* Password */}
                                <div className="checkout-page__input-box">
                                    <input type="password" name="password" value={formData.password} placeholder="Password *" required onChange={handleChange} />
                                </div>
                                {/* Confirm Password */}
                                <div className="checkout-page__input-box">
                                    <input type="password" name="confirmPassword" value={formData.confirmPassword} placeholder="Confirm Password *" required onChange={handleChange} />
                                </div>
                                <div className="text-right d-flex justify-content-end">
                                    <button style={{ marginRight: "30px" }}
                                        type="submit" 
                                        className="alefox-btn"
                                    >
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        Submit
                                    </button>
                                  <Link to='/booking'> <button 
                                        type="button"
                                        className="alefox-btn"
                                    >
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        <span className="alefox-btn__item"></span>
                                        Cancel
                                    </button></Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup;
