import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import '../../style/productmanagement.css'; // Import the CSS file

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    pname: '',
    pdesc: '',
    CAD: '',
    USD: '',
    quantity: '',
    image: ''

  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://gardengrowbackend.onrender.com/products/getProduct');
      setProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'CAD' || name === 'USD') {
        setFormData({ ...formData, [name]: parseFloat(value) || 0 }); // Parse to float, default to 0 if NaN
    } else {
        setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    for (const key in formData) {
      if (key === 'image' && !formData.image) {
        alert('Please upload an image.'); // Alert if no image is uploaded
        return; // Prevent submission if no image
      }
      form.append(key, formData[key]);
    }
     
    try {
      if (selectedProduct) {
        await axios.put(`https://gardengrowbackend.onrender.com/products/updateProduct/${selectedProduct._id}`, form);
      } 
      else {
        
        await axios.post('https://gardengrowbackend.onrender.com/products/createProduct', form);

      }
      fetchProducts();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving product:', error.response.data);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setFormData({
      pname: product.pname,
      pdesc: product.pdesc,
      image: '',
      CAD: product.CAD,
      USD: product.USD,
      quantity: product.quantity,
    });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://gardengrowbackend.onrender.com/products/deleteProduct/${id}`);
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setFormData({
      pname: '',
      pdesc: '',
      image: '',
      CAD: '',
      USD: '',
      quantity: '',
    });
  };

  return (
    <>
      <div className="product-management">
        {/* Product Creation Section */}
        <div className="product-creation">
          <h3>Create New Product</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Name</label>
              <input type="text" name="pname" value={formData.pname} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" name="pdesc" value={formData.pdesc} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Image</label>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div className="form-group">
              <label>CAD</label>
              <input type="text" name="CAD" value={formData.CAD} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>USD</label>
              <input type="text" name="USD" value={formData.USD} onChange={handleInputChange} required />
            </div>
            <div className="form-group">
              <label>Qty</label>
              <input type="number" name="quantity" value={formData.quantity} onChange={handleInputChange} required />
            </div>
            <Button type="submit" className="submit-button">Create Product</Button>
          </form>
        </div>

        {/* Existing Products List */}
        <div className="product-list">
          {products.map((product) => (
            <div key={product._id} className="product-card">
              <h5>{product.pname}</h5>
              <p>{product.pdesc}</p>
              <p>CAD: {product.CAD}</p>
              <p>USD: {product.USD}</p>
              <p>Qty: {product.quantity}</p>
              <div className="button-group">
                <Button className="edit-button" onClick={() => handleEdit(product)}>Edit</Button>
                <Button className="delete-button" onClick={() => handleDelete(product._id)}>Delete</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductManagement;