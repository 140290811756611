import React, { useEffect } from "react";
import Head from "../header";
import Footer from "../footer";
import Slider from "react-slick"; // Ensure you have react-slick installed

function CocoFibre(){
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pr-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pr-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pr-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pr-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pr-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pr-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pr-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pr-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pr-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Coco Fibre</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Coco Fibre consists of long, durable Fibre extracted from coconut husks. Known for its strength and natural resistance to decay, coco Fibre is a versatile and sustainable choice for numerous agricultural and horticultural applications.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Enhanced Soil Structure: Adds bulk and structure to soil, promoting improved aeration and drainage.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Excellent Drainage: Prevents waterlogging by allowing excess water to flow through while maintaining moisture at the root level.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Erosion Control: Naturally binds soil together, making it a preferred choice for erosion-prone areas.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Sustainable: 100% biodegradable and free from synthetic chemicals.</li>
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                    	<b>Coco Fibre</b>, also known as coir Fibre, is extracted from the husk of the coconut and is known for its durability and versatility. We offer customizable sizes to meet a variety of agricultural and horticultural needs.
		Benefits: Coco Fibre improves aeration in soil, making it ideal for crops requiring high drainage. It is also an excellent solution for erosion control, providing stability and preventing soil degradation.
		Applications: Widely used in erosion control mats, soil mixes, horticulture, landscaping, and even as lining in hanging baskets to enhance root aeration.


                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            <li><span className="icon-check-mark" /><h6>Greenhouses:</h6> Used in potting mixes to improve aeration and moisture retention, ensuring healthier growth for greenhouse plants.</li>
                            <li><span className="icon-check-mark" /><h6>Hydroponics:</h6> Mixed with coco peat to enhance root anchorage and promote healthy air circulation around roots.</li>
                            <li><span className="icon-check-mark" /><h6>Micro Cultivations:</h6> Ideal for supporting the growth of small plants and herbs, especially in confined spaces like micro farms.</li>
                            <li><span className="icon-check-mark" /><h6>Cannabis Farms:</h6> Helps prevent root rot by allowing excess water to drain effectively, making it valuable for cannabis cultivation.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Sold as a component in potting and hanging basket mixes, appealing to retail customers.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Popular as a natural liner for hanging baskets and containers, adding a rustic, eco-friendly aesthetic.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Customizable sizes to meet various commercial and home gardening needs.</p>
                        <h3 className="product-details__description__title">How to Use Coco Fibre:</h3>
                        <p className="product-details__description__text">
                            Coco Fibre is perfect for improving soil structure, mulching, and creating sustainable growing environments. Here’s how to incorporate it into your gardening or agricultural practices:
                        </p>
                        <ul>
                            <li><h6>Soil Amendment:</h6> Mix coco Fibre with your garden soil to improve its aeration and water retention. Add about 10-20% coco Fibre by volume to the soil for optimal results.</li>
                            <li><h6>In Hydroponic Systems:</h6> Use coco Fibre in hydroponic systems, especially in combination with other media like perlite, to enhance root health and moisture management.</li>
                            <li><h6>As Mulch:</h6> Spread a layer of coco Fibre around plants to help retain moisture, suppress weeds, and regulate soil temperature. It’s especially beneficial for plants in dry areas or raised beds.</li>
                            <li><h6>As Potting Mix:</h6> For container plants, use coco Fibre mixed with compost or other soil-less mediums to improve texture and drainage.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}
export default CocoFibre;