import React from "react";
import Head from "../Layout/header";
import Product1 from "../Layout/Products/product1";
import Footer from "../Layout/footer";
import ProductCard from "../Layout/Products/products";


function Coco(){


    return (
        <>
        <Head/>
        <br/><br/><br/><br/><br/><br/>
        <Product1/>
        <ProductCard/>
        <Footer/>
        </>
    )
}
export default Coco;