import React from "react";
import { Link } from "react-router-dom";
import { SiCrowdsource } from "react-icons/si";
import { MdCompost, MdAgriculture } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { HiMiniShieldCheck } from "react-icons/hi2";

function Whybody(){
    return(
        <>
           <section className="service-details">
  <div className="container">
    <div className="row gutter-y-60">
      <div className="col-md-12 col-lg-4">
        <div className="service-sidebar">
          {/* <div className="service-sidebar__single">
            <h3 className="service-sidebar__title">Technical Description of Cocopeat </h3>
            <ul className="list-unstyled service-sidebar__nav">
              <li><a href> Water Holding Capacity 750-1100 %</a></li>
              <li><a href> Nitrogen 0.005</a></li>
              <li><a href> Phosphate 0.03%</a></li>
              <li><a href> Potash 0.25%</a></li>
              <li><a href> Organic Carbon 10-12 %</a></li>
              <li><a href> pH 5.5-6.5 %</a></li>
              <li><a href> Conductivity less than 0.Smilli/mhos</a></li>
              <li><a href> C/N ratio 01:00.0</a></li>
              <li><a href> Total Organic Matter 75-85 %</a></li>
              <li><a href> Source: llahi, 2017</a></li>

            </ul>
          </div> */}
          <div className="service-sidebar__single">
            <div className="service-sidebar__contact" style={{"background-image":"url(assets/images/shapes/service-contact-bg-1-1.jpg)"}}>
              <h3 className="service-sidebar__contact__title">Book a FREE Professional Gardening Consultation!</h3>{/* /.service-sidebar__contact__title */}
             <a href="https://calendly.com/contactus-gardengrow/30min" target="_blank" className="alefox-btn">
                <span className="alefox-btn__item" />
                <span className="alefox-btn__item" />
                <span className="alefox-btn__item" />
                <span className="alefox-btn__item" />
                Book a Consultation
              </a>
            </div>{/* /.service-sidebar__contact */}
          </div>
        </div>{/* /.sidebar */}
      </div>{/* /.col-md-12 col-lg-4 */}
      <div className="col-md-12 col-lg-8">
        <div className="service-details__content">
          <div className="service-details__thumbnail">
            <img src="assets/images/resources/service-d-3.jpg" alt="Organic Product" />
          </div>{/* /.service-details__thumbnail */}
          <h3 className="service-details__title">Why Choose Garden Grow?</h3>{/* /.service-details__title */}
          <p className="service-details__text">
          An agriculture company headquartered in Toronto, Canada with its factories in India, Philippines and Indonesia are able to manufacture and export<b> coco peat, coco fibre </b>related products to Canada, USA, Europe, Africa and Australia. With a commitment to quality, sustainability, and customer satisfaction, Garden Grow is a trusted partner for coco peat and fibre products. 
          </p>
          {/* /.service-details__text */}
          <p className="service-details__text">
         <h5> Expertise:</h5> Years of industry experience allows us to deliver superior products crafted to meet diverse agricultural needs.
          </p>
          <p className="service-details__text">
          <h5> Global Reach:</h5> We provide a seamless experience for clients worldwide, ensuring timely and secure delivery of our products.
          </p>
         
          <p className="service-details__text">
          <h5> Sustainable Production:</h5> Our eco-friendly practices minimize waste and support sustainable farming, ensuring that every product contributes positively to the environment.
          </p>
          <p className="service-details__text">
          <h5> Customer-Centric Approach:</h5> We prioritize customer needs, offering customized solutions and support to maximize the value of our products for each unique application.
          </p>
          {/* /.service-details__text */}
          <h3 className="service-details__heading">Our Process</h3>{/* /.service-details__heading */}
          <p className="service-details__text">
          At <b>Garden Grow</b>, every step in our process is designed to maintain the quality and sustainability of our products.
          </p>{/* /.service-details__text */}
          <div className="row gutter-y-30 service-details__box-wrapper">
            <div className="col-md-6">
              <div className="service-details__box">
                <div className="service-details__box__icon"><span><SiCrowdsource />
                </span></div>
                <h3 className="service-details__box__title">Sourcing:</h3>
                <p className="service-details__box__text">We partner with local coconut farmers to source the highest quality coconut husks.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-details__box">
                <div className="service-details__box__icon"><span><MdCompost />
                </span></div>
                <h3 className="service-details__box__title">Preparation:</h3>
                <p className="service-details__box__text">The husks are soaked, cleaned and processed to remove any impurities, ensuring optimal pH and salt levels.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-details__box">
                <div className="service-details__box__icon"><span><MdAgriculture /></span></div>
                <h3 className="service-details__box__title">Production:</h3>
                <p className="service-details__box__text">Using advanced technology, we process the coir into blocks, grow bags, fibres, and other forms tailored to agricultural, greenhouse, horticultural needs and for home gardens</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-details__box">
                <div className="service-details__box__icon"><span><FaHandHoldingDollar /></span></div>
                <h3 className="service-details__box__title">Pricing:</h3>
                <p className="service-details__box__text">By innovating continuously and adapting to new agricultural technologies, we aim to make eco-friendly products accessible and affordable for all growers.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-details__box">
                <div className="service-details__box__icon"><span><HiMiniShieldCheck /></span></div>
                <h3 className="service-details__box__title">Quality Control: </h3>
                <p className="service-details__box__text">Each batch is thoroughly tested to meet international standards, ensuring the quality and performance of every product.</p>
              </div>
            </div>
          </div>
          <h3 className="service-details__heading">Our Vision</h3>{/* /.service-details__heading */}

          <p className="service-details__text">
          To be a global leader in coco peat and fibre related products and continue to support a greener future for agriculture and horticulture industries worldwide.
          </p>{/* /.service-details__text */}
          <div className="row gutter-y-30 service-details__benefit">
            <div className="col-md-5">
              <img src="assets/images/resources/service-d-1-1.jpg" alt="alefox" />
            </div>
            <div className="col-md-7">
              <div className="service-details__benefit__content">
                <h4 className="service-details__benefit__title">Industries We Serve</h4>
                <p className="service-details__benefit__text">
                Our coco peat and fibre products cater to various industries worldwide including the following 
                </p>
                <ul className="service-details__benefit__list">
                  <li><span className="service-details__benefit__list__icon"><i className="fas fa-check-circle" /></span>Agriculture</li>
                  <li><span className="service-details__benefit__list__icon"><i className="fas fa-check-circle" /></span>Horticulture</li>
                  <li><span className="service-details__benefit__list__icon"><i className="fas fa-check-circle" /></span>Landscaping</li>
                  <li><span className="service-details__benefit__list__icon"><i className="fas fa-check-circle" /></span>Hydroponics</li>
                </ul>
                <p className="service-details__benefit__text">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>
         
        </div>{/* /.service-details__content */}
      </div>{/* /.col-md-12 col-lg-8 */}
    </div>{/* /.row */}
  </div>{/* /.container */}
</section>
        </>
    )
}

export default Whybody;

