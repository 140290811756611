import React, { useState } from "react";

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      title: "What is Coco Peat and why should I use it?",
      content:
        "Cocopeat is a fine spongy material left after the extraction of the coir from the coconut husk. It is also known as coir pith or coir dust. Coco peat is widely used as a soil amendment or growing medium. It improves water retention, aeration and nutrient-holding capacity, making it ideal for home owners, agriculture, greenhouses, hydroponics and horticulture."
    },
    {
      title: "What is the difference between coco peat and coco fibre?",
      content:
        "Coconut husk is the raw material of which coco peat and coco fibre is extracted. On each coconut husk 67% will be coco peat and the remaining 33% is coco fibre or coir."
    },
    {
      title: "Is coco peat a good media for flowers, cannabis, fruits and vegetables?",
      content:
        "Yes, coco peat is an excellent growing medium for many flowers, fruits and vegetables and are widely used by home owners, greenhouses, horticulture and in hydroponics. For Cannabis production, coco peat and husk are one of the best medium specially produced in greenhouses. Examples of other fruits & vegetables, carrot, capsicum, tomato, cucumber, ginger, muskmelon, blueberry etc."
    },
    {
      title: "Can coco mulch be used in place of normal wood mulch in my garden or backyard?",
      content:
        "Yes, in landscaping coco husk can be used in place of a normal wood mulch as it helps improve soil structure, controls weed, holds water 7.5 to 11 times to it weight, retains moisture, better texture and an absolute eco-friendly sustainable natural product."
    },
    {
      title: "What are the different coco products Garden Grow offer?",
      content:
        "Garden Grow offers 1 KG Coco Peat Block, 1 KG Coco Mulch, 5 KG Coco Peat block, 5 KG Coco Husk blocks, 3 and 4 KG Grow bags and Coir Door Mats and rugs."
    },
    {
      title: "Is coco peat environmentally friendly?",
      content:
        "Absolutely. Coco peat is biodegradable, renewable, and serves as an eco-friendly alternative to peat moss, helping reduce environmental impact."
    },
    {
      title: "Does coco peat or husk are mixed with chemicals?",
      content:
        "No, it is a natural product from the coconut husk and no amount of chemicals are mixed in the products."
    },
    {
      title: "Does coco peat be used as animal bedding?",
      content:
        "Yes, coco peat is widely used as an animal bedding because of its high bulk density, high moisture absorption capacity and biodegradability. It absorbs animal urine several times its weight because of its air-filled porosity and thus separates feces from urine, consequently reduces at a large amount of ammonia emissions and helps in avoiding bad smell and largely, prevents flies."
    },
    {
      title: "Do you ship internationally?",
      content:
        "Yes, Garden Grow serves clients worldwide. We supply coco peat and husks in large quantity to Greenhouses and hydroponics that produce vegetables, fruits, cannabis etc. Our clients are from USA, Canada, Europe, Africa, Middle East and Australia. For more details on international shipping, please reach out through our contact page."
    },
    {
      title: "Where does Garden Grow manufacture its coco products?",
      content:
        "We have factories in India, Philippines and Indonesia and we export the best quality of growing medium coco products to the world."
    },
    {
      title: "Can I order it from Garden Grow products for my home use?",
      content:
        "Yes, Garden Grow offers its products on its website and you can directly order and will be shipped to your home."
    },
    {
      title: "Can coco peat be used in my plant pots indoor and outdoor?",
      content:
        "Yes, coco peat can be used to grow a lot of indoor and outdoor plants which improves porosity, wettability and water holding capacity. It helps produce stronger and more fibrous roots."
    }
  ];

  return (
    <section className="faq-one faq-one--page">
      <div className="container">
        <div className="row">
          {faqData.map((item, index) => (
            <div
              className="col-xl-6 wow fadeInUp"
              data-wow-delay="00ms"
              key={index}
            >
              <div
                className="faq-one__accordion alefox-accrodion"
                data-grp-name="alefox-accrodion"
              >
                <div
                  className={`accrodion ${activeIndex === index ? "active" : ""}`}
                >
                  <div
                    className="accrodion-title"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h4>
                      {item.title}
                      <span className="accrodion-title__icon"></span>
                    </h4>
                  </div>
                  {activeIndex === index && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p style={{ textAlign: 'justify' }}>{item.content}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Faq;
