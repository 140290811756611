import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Homeservice from '../service';


const ProductCard = () => {
   

    return (
       <>
       <Homeservice></Homeservice>
       </>
    );
};

export default ProductCard;
