import React, { useState } from "react";
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import Bg from '../assets/images/backgrounds/coco-peat.mp4'

Modal.setAppElement('#root'); 

function Homevideo() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            <section className="video-one">
                {/* Replace background image with video */}
                <video className="video-one__bg" autoPlay loop muted playsInline style={{ objectFit: 'cover' }}>
                    <source src={Bg} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video>
                {/* /.video-one__bg */}
                {/* Remove the shape div if not needed */}
                {/* <div className="video-one__shape" style={{ backgroundImage: "url(assets/images/shapes/video-shape-1.png)" }} /> */}
                <div className="container">
                   
                    <h3 className="video-one__title">
                        Harnessing the Power of Coco Peat in Agricultural Development
                    </h3>
                    <Link to='/process'>
                        <button className="alefox-btn">
                            <span className="alefox-btn__item" />
                            <span className="alefox-btn__item" />
                            <span className="alefox-btn__item" />
                            <span className="alefox-btn__item" />
                            Discover More
                        </button>
                    </Link>
                </div>{/* /.container */}
            </section>{/* /.video-one */}

          
        </>
    )
}

export default Homevideo; 