import React from "react";
import Head from "../Layout/header";
import Signup from "../Layout/signup/signup";
import Footer from "../Layout/footer";

function Mainsignup(){
    return(
        <>
        <Head/>
        <br/><br/>
        <Signup/>
        <Footer/>
        </>
    )
}

export default Mainsignup;