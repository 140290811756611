import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import '../style/header.css'

function Head() {
    const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);
    const [isTopBarVisible, setIsTopBarVisible] = useState(true);
    const location = useLocation();
    const [headerStyle, setHeaderStyle] = useState({ height: '160px', transition: 'height 0.3s' });
    const [logoStyle, setLogoStyle] = useState({ width: '150px', transition: 'width 0.3s' });

    const toggleMobileNav = () => {
        setIsMobileNavExpanded(prevState => !prevState);
    };

    const closeMobileNav = () => {
        setIsMobileNavExpanded(false);
    };

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsTopBarVisible(false);
            setHeaderStyle({ height: '120px', transition: 'height 0.3s' });
            setLogoStyle({ width: '120px', transition: 'width 0.3s' });
        } else {
            setIsTopBarVisible(true);
            setHeaderStyle({ height: '140px', transition: 'height 0.3s' });
            setLogoStyle({ width: '150px', transition: 'width 0.3s' });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
            {isTopBarVisible && (
                <div className="topbar-one" >
                    <div className="container">
                        <div className="topbar-one__inner">
                            <ul className="list-unstyled topbar-one__info">
                                <li className="topbar-one__info__item">
                                    <i className="icofont-wall-clock topbar-one__info__icon" />
                                    Mon - Sat 09:00 EST - 17:00 EST - Sunday Holiday
                                </li>
                                <li className="topbar-one__info__item">
                                    <i className="icofont-location-pin topbar-one__info__icon" />
                                   <b> Headquarters</b> - Toronto, Canada 
                                   {/* <b> Factory</b> - India | Philippines | Indonesia    */}
                                </li>
                                <li className="topbar-one__info__item">
                                    <i className="icofont-location-pin topbar-one__info__icon" />
                                   {/* <b> Headquarters</b> - Toronto, Canada  */}
                                   <b> Factory</b> - India | Philippines | Indonesia   
                                </li>
                            </ul>
                            <div className="topbar-one__right">
                                <div className="topbar-one__social">
                                    <a href="https://www.facebook.com/share/aYHMfunZtnRqGWzr/" style={{ color: 'blue' }} target="_blank">
                                        <i className="fab fa-facebook-f" aria-hidden="true" />
                                        <span className="sr-only">Facebook</span>
                                    </a>
                                    <a href="https://wa.me/+16479887328?text=Hello,%20I%20need%20assistance!" style={{ color: 'green' }} target="_blank">
                                        <i className="fab fa-whatsapp" aria-hidden="true" />
                                        <span className="sr-only">whatsapp</span>
                                    </a>
                                    <a href="https://www.linkedin.com/company/garden-grow/" style={{ color: '#0077b5' }} target="_blank">
                                        <i className="fab fa-linkedin" aria-hidden="true" />
                                        <span className="sr-only">Linkedin</span>
                                    </a>
                                    <a href="https://www.instagram.com/official_gardengrow/profilecard/?igsh=MXFocWVtZzZ6OWVzcA==" style={{ color: '#E1306C' }} target="_blank">
                                        <i className="fab fa-instagram" aria-hidden="true" />
                                        <span className="sr-only">Instagram</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <header className="main-header" style={headerStyle}>
                <div className="container-fluid">
                    <div className="main-header__inner">
                        <div className="main-header__logo">
                            <a href="/">
                                <img src="assets/images/logo-dark.png" alt="Logo" style={logoStyle} />
                            </a>
                        </div>
                        <nav className={`main-header__nav main-menu ${isMobileNavExpanded ? 'expanded' : ''}`}>
                            <ul className="main-menu__list">
                                <li><Link to="/">Home</Link></li>
                                <li className="dropdown">
                                <Link to="/about"> <a >About Us</a></Link>
                                <ul>
                                    <li><Link to="/why">Why Choose Garden Grow</Link></li>
                                </ul>
                            </li>
                               
                                <li className="dropdown">
                                <a >Growing Media</a>
                                <ul>
                                    <li><Link to="/product">Our Products</Link></li>
                                    <li><Link to="/process">Production Process</Link></li>
                                </ul>
                            </li>


                                <li className="">
                                  <Link to='/resource'> <a href>Resource For Growers</a></Link> 
                                   
                                </li>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/booking">Buy Now</Link></li>
                            </ul>
                        </nav>
                       
                        <div className="main-header__right">
                            <div className="mobile-nav__btn" onClick={toggleMobileNav}>
                                <span />
                                <span />
                                <span />
                            </div>
                            <div className="main-header__right__border" />
                            <div className="main-header__call">
                                <div className="main-header__call__icon">
                                    <span className="icon-phone-call-two" />
                                </div>
                                <div className="main-header__call__title">Toll-free Number:</div>
                                <a className="main-header__call__text" >1-888-805-PEAT(7328)</a>
                            </div>
                        </div>
                    </div>
                    {isMobileNavExpanded && (
                        <div className={`mobile-menu ${isMobileNavExpanded ? 'fade-in slide-in' : 'fade-out'}`} style={{ backgroundColor: 'white' }}>

                            <div className="text-right d-flex justify-content-end">
                             
                                    </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/">Home</Link>
                            </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/about">About Us</Link>
                            </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/product">Our Products</Link>
                            </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/process">Production Process</Link>
                            </div>
                           
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/resource">Resource For Growers</Link>
                            </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/faq">FAQ</Link>
                            </div>
                            <div className="mobile-menu__item" onClick={closeMobileNav}>
                                <Link to="/booking">Buy Now</Link>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        </div>
    );
}

export default Head;
