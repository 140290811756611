import React from "react";
import { Link } from "react-router-dom";
import World from "../assets/images/World.png";

function Homevid() {
    return (
        <>
        <div className="image-container">
            <img src={World} alt="world" className="responsive-image" />
        </div>
        </>
    );
}

export default Homevid;
