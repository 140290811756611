import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


function Homeservice() {
    
    return (
        <>
          <section className="service-one">
  <div className="service-one__shape" style={{"background-image":"url(assets/images/resources/service-shape-1.png)"}} />
  <div className="container">
    <div className="sec-title text-center">
      <h6 className="sec-title__tagline bw-split-in-right"><span className="sec-title__tagline__left-leaf" style={{"background-image":"url(assets/images/shapes/leaf.png)"}} />Our Best Products<span className="sec-title__tagline__right-leaf" style={{"background-image":"url(assets/images/shapes/leaf.png)"}} /></h6>{/* /.sec-title__tagline */}
      <h3 className="sec-title__title bw-split-in-left">We Providing High Quality</h3>{/* /.sec-title__title */}
    </div>{/* /.sec-title */}
    <div className="row gutter-y-30">
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="00ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
              <a>Coco Peat</a>
            </h3>{/* /.service-title */}
           
           <Link to='/cocopeat'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
                </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-1.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="50ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
              <a >⁠Coco Fibre
              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/cocofibre'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-2.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="100ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
              <a >Coco Mulch
              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/cocomulch'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-3.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="150ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
                <a >⁠Grow Bags
              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/growbags'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-4.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="150ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
                <a >⁠Coir Garden Pots
              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/coirgardenpots'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-55.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="150ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
                <a >Coir ⁠Door Mats

              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/coirdoormats'> <a className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-66.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="150ms">
        <div className="service-one__item">
          <div className="service-one__item__content">
            <div className="service-one__item__icon">
            </div>{/* /.service-icon */}
            <h3 className="service-one__item__title">
                <a >⁠Animal Bedding 

              </a>
            </h3>{/* /.service-title */}
           
            <Link to='/animalbedding'> <a  className="service-one__item__link">
              <i className="icofont-arrow-right" />
              Read more
            </a></Link>
          </div>
          <div className="service-one__item__image">
            <img src="assets/images/resources/service-1-77.jpg" alt="alefox" />
          </div>
        </div>{/* /.service-item */}
      </div>
    </div>
    <div className="service-one__bottom">
      <div className="row">
        <div className="col-lg-5">
          <div className="service-one__bottom__content">
            <h3 className="service-one__bottom__title">Take Everyday</h3>
            <ul className="service-one__bottom__list">
              <li>
                <span className="service-one__bottom__list__icon"><i className="fas fa-check-circle" /></span>
                Cutting-Edge Technology: Our machines are designed with advanced technology to deliver efficient and precise coir processing.
              </li>
              <li>
                <span className="service-one__bottom__list__icon"><i className="fas fa-check-circle" /></span>
                Versatile Applications: From coco peat blocks to coir fiber bales, our machinery caters to a wide range of coir-based production needs.
              </li>
             
            </ul>
           
          </div>
        </div>
        <div className="col-lg-7">
          <div className="service-one__bottom__image">
            <div className="row">
              <div className="col-md-6">
                <img src="assets/images/resources/sb-1.jpg" alt="alefox" />
              </div>
              <div className="col-md-6">
                <img src="assets/images/resources/sb-2.jpg" alt="alefox" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        </>
    );
}

export default Homeservice;
