import React, { useEffect, useState } from 'react';

const OrderDetails = ({ orderData, billingDetails, shippingDetails }) => {
    const [bookingData, setBookingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookingData = async () => {
            try {
                const response = await fetch('https://gardengrowbackend.onrender.com/booking/getBooking');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setBookingData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBookingData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    // Check if orderData is defined
    if (!orderData) {
        return <p>No order data available.</p>;
    }

    return (
        <div className="order-details">
            <h2>Your Order Details</h2>
            <h3>Product Information</h3>
            <p>Product ID: {orderData.pId}</p>
            <p>Product Name: {orderData.pname}</p>
            <p>Product Price: {orderData.price}</p>
            <p>Quantity: {orderData.quantity}</p>
            <p>Total Price: {orderData.totalPrice}</p>

            <h3>Billing Address</h3>
            <p>First Name: {billingDetails.firstname}</p>
            <p>Last Name: {billingDetails.lastname}</p>
            <p>Mobile No: {billingDetails.mobileno}</p>
            <p>Address: {billingDetails.address}</p>
            <p>Country: {billingDetails.country}</p>
            <p>State: {billingDetails.state}</p>
            <p>City: {billingDetails.city}</p>
            <p>Zip Code: {billingDetails.zipcode}</p>

            {shippingDetails && Object.keys(shippingDetails).length > 0 && (
                <>
                    <h3>Shipping Address</h3>
                    <p>First Name: {shippingDetails.firstname}</p>
                    <p>Last Name: {shippingDetails.lastname}</p>
                    <p>Mobile No: {shippingDetails.mobileno}</p>
                    <p>Address: {shippingDetails.address}</p>
                    <p>Country: {shippingDetails.country}</p>
                    <p>State: {shippingDetails.state}</p>
                    <p>City: {shippingDetails.city}</p>
                    <p>Zip Code: {shippingDetails.zipcode}</p>
                </>
            )}
        </div>
    );
};

export default OrderDetails; 