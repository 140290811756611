import React from "react";
import { Link } from "react-router-dom";



function Processhead(){



    return(
        <>
         <section className="page-header">
            <div className="page-header__shape-left" />
            <div className="page-header__shape-right" />
            <div className="page-header__b" />
            {/* /.page-header__bg */}
            <div className="container">
              <h2 className="page-header__title bw-split-in-down">Production Process</h2>
             {/* /.thm-breadcrumb list-unstyled */}
            </div>{/* /.container */}
          </section>
        </>
    )
}
export default Processhead;