import React from "react";


function Homesec(){


    return(

        <>
          <section className="cta-one">
                        <div className="cta-one__shape" style={{ "background-image": "url(assets/images/shapes/cta-1-1.png)" }} />
                        <div className="container wow fadeInUp">
                            <h3 className="cta-one__title">
                            Quality Assurance
                            </h3>
                            <p className="cta-one__text">
                            Our products undergo stringent quality control measures to ensure they meet international standards and our client’s needs. From sterilization processes to pH balancing and from washing to sundry, we ensure our coco coir and fibre are optimized for the best growing results.
                            </p>
                           
                        </div>
                    </section>
        </>
    )
}

export default Homesec;