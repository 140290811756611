import React from "react";
import Head from "../Layout/header";
import AboutComponent from "../Layout/about";
import Homebody from "../Layout/Body1";
import Homeservice from "../Layout/service";
import Homevideo from "../Layout/videosec";
import Homevid from "../Layout/videosec2";
import Homesec from "../Layout/Homesec";
import Footer from "../Layout/footer";
import WelcomeHome from "../Layout/Welcomehome";

function HomePage(){



    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <Homebody/>
        <WelcomeHome/>
        <AboutComponent/>
        <Homeservice/>
        <Homevideo/>
        <br/><br/>
        <Homevid/>
        <br /> <br />
        <Homesec/>
        <Footer/>

        </>
    )
}
export default HomePage;