import React from "react";
import { Link, useLocation } from "react-router-dom";


function AboutComponent(){
    const location = useLocation();


    return(
        <>
       
         <section className="about-one">
                        <div className="about-one__shape" style={{ "background-image": "url(assets/images/resources/about-1-shape-1.png)" }} />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-one__image wow fadeInLeft" data-wow-delay="200ms">
                                        <img src="assets/images/resources/about-1-1.jpeg" alt="alefox" />
                                        <div className="about-one__image__two">
                                            <img src="assets/images/resources/about-1-2.jpg" alt="alefox" />
                                        </div>
                                        <div className="about-one__image__three">
                                            <img src="assets/images/shapes/about-1-brand.png" alt="alefox" />
                                        </div>
                                    </div>{/* /.about-one__image */}
                                </div>{/* /.col-lg-6 */}
                                <div className="col-lg-6 wow fadeInRight" data-wow-delay="300ms">
                                    <div className="about-one__content">
                                        <div className="sec-title text-left">
                                            
                                            <h3 className="sec-title__title bw-split-in-left"><span className="sec-title__tagline__left-leaf" style={{ "background-image": "url(assets/images/shapes/leaf.png)" }} />About Us</h3>{/* /.sec-title__title */}
                                        </div>{/* /.sec-title */}
                                       
                                        <div className="about-one__content__quote">
                                            <i className="icon-farmer-two" />
                                            Garden Grow Inc., an agriculture company headquartered in Toronto, Canada with its factories in India, Philippines and Indonesia is a trusted provider of premium-quality growing media solutions crafted from coconut coir. We are committed to support the agriculture and horticulture industries with innovative products that promote efficient and effective cultivation. Our expertise lies in delivering sustainable and customizable growing media solutions to meet the evolving needs of growers worldwide.
                                        </div>
                                        <div className="about-one__content__border" />
                                        <ul className="about-one__content__list">
                                            <li><span className="icon-check-mark" />We are happy to share that we are one of the most affordable farming supplies organizations in the global market.</li>
                                            <li><span className="icon-check-mark" />Our mission is to promote a green future by offering biodegradable and renewable growing solutions to all farmers across the globe.</li>
                                            <li><span className="icon-check-mark" />We export to our premium coco peat products to America, Canada, Australia, European and African countries.</li>
                                        </ul>
                                       
                                    </div>{/* /.why-choose-two__content */}
                                    {/* /.why-choose-two__content */}
                                </div>{/* /.col-lg-6 */}
                            </div>{/* /.row */}
                            <div className="text-right d-flex justify-content-end">
                                {location.pathname === "/" && (
                                    <Link to="/about">
                                        <button type="submit" className="alefox-btn">
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            Learn More
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                        {location.pathname === "/about" && (
             <div style={{ width: "90%", maxWidth: "1200px", padding: "0 15px" }}>
                                        <h3 className="sec-title__title">Who We Are</h3>
                                        <p>At Garden Grow, we leverage the unique properties of coconut coir to develop a diverse range of products, including coco peat, coco fibre, coco husk chips, grow bags, coco mulch, animal bedding and coir garden pots. Our products are engineered to enhance soil quality, improve plant health, and deliver consistent yield to growers. With customizable sizes and specifications, we provide tailored growing media solutions for growers of all scales, from small-scale gardeners to large agricultural enterprises.</p>
                                        <h3 className="sec-title__title">Our Mission</h3>
                                        <p>Our mission is to provide growers with reliable, efficient, and eco-conscious growing media that enhances agricultural productivity and addresses the challenges of modern farming. By delivering exceptional products and fostering innovation, we strive to empower our clients to achieve superior outcomes while contributing to a more sustainable future.</p>
                                        <h3 className="sec-title__title">Growing the Future</h3>
                                        <p>At Garden Grow, we are dedicated to advancing agriculture and horticulture through high-quality coir-based solutions. By combining expertise, innovation, and a deep commitment to quality, we help growers cultivate success today and prepare for the challenges of tomorrow.</p>
                                        </div>
                        )}
        </div>{/* /.container */}
                    </section>
                    {/* /.about-one */}

        </>
    )
}

export default AboutComponent;