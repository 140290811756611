import React from "react";
import Head from "../Layout/header";
import Footer from "../Layout/footer";
import Whybody from "../Layout/why/whybody";

function Why(){
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/><br/>
        <Whybody/>
        <Footer/>
        </>
    )
}

export default Why;