import React from "react";
import { Link } from "react-router-dom";



function Footer() {



    return (
        <>
            <footer className="main-footer">
                <div className="main-footer__bg"  />
                {/* /.main-footer__bg */}
                <div className="main-footer__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-xl-3">
                                <div className="footer-widget footer-widget--about">
                                    <a className="footer-widget__logo">
                                        <img src="assets/images/logo-dark.png" width={200} alt="GARDEN GROW" />
                                    </a>
                                    <div className="footer-widget__social">
                                        <a href="https://www.facebook.com/share/aYHMfunZtnRqGWzr/" style={{ color: 'blue' }} target="_blank">
                                            <i className="fab fa-facebook-f" aria-hidden="true" />
                                            <span className="sr-only">Facebook</span>
                                        </a>
                                        <a href="https://wa.me/+16479887328?text=Hello,%20I%20need%20assistance!" style={{ color: 'green' }} target="_blank">
                                            <i className="fab fa-whatsapp" aria-hidden="true" />
                                            <span className="sr-only">whatsapp</span>
                                        </a>
                                        <a href="https://www.linkedin.com/company/garden-grow/" style={{ color: '#0077b5' }} target="_blank">
                                            <i className="fab fa-linkedin" aria-hidden="true" />
                                            <span className="sr-only">Linkedin</span>
                                        </a>
                                        <a href="https://www.instagram.com/official_gardengrow/profilecard/?igsh=MXFocWVtZzZ6OWVzcA==" style={{ color: '#E1306C' }} target="_blank">
                                            <i className="fab fa-instagram" aria-hidden="true" />
                                            <span className="sr-only">Instagram</span>
                                        </a>
                                    </div>
                                    {/* /.footer-widget__social */}
                                </div>{/* /.footer-widget */}
                            </div>{/* /.col-md-6 */}
                            <div className="col-md-4 col-xl-3" >
                                <div className="footer-widget footer-widget--links">
                                    <h2 className="footer-widget__title">Our Products</h2>{/* /.footer-widget__title */}
                                    <ul className="list-unstyled footer-widget__links">
                                        <li><Link to='/cocopeat'><a href>CoCo Peat</a></Link></li>
                                        <li><Link to='/cocofibre'><a href>Coco Fibre</a></Link></li>
                                        <li><Link to='/cocomulch'><a href>Coco Mulch</a></Link></li>
                                        <li><Link to='/growbags'><a href>⁠Grow Bags</a></Link></li>
                                        <li><Link to='/coirdoormats'><a href>Coir ⁠Door Mats</a></Link></li>
                                        <li><Link to='/coirgardenpots'><a href>⁠Coir Garden Pots</a></Link></li>
                                        <li><Link to='/animalbedding'><a href> ⁠Animal Bedding</a></Link></li>



                                    </ul>{/* /.list-unstyled footer-widget__links */}
                                </div>
                            </div>
                            {/* /.col-md-6 */}
                            <div className="col-md-6 col-xl-2">
                                <div className="footer-widget footer-widget--links footer-widget--links-two">
                                    <h2 className="footer-widget__title">Links</h2>{/* /.footer-widget__title */}
                                    <ul className="list-unstyled footer-widget__links">
                                        <li><Link to='/about'><a>About Us</a></Link></li>
                                        <li><Link to='/why'>Why Choose Garden Grow</Link></li>

                                        <li><Link to='/booking'><a>Buy Now</a></Link></li>
                                        <li><Link to='/faq'><a>FAQ</a></Link></li>

                                    </ul>{/* /.list-unstyled footer-widget__links */}
                                </div>{/* /.footer-widget */}
                            </div>{/* /.col-md-6 */}
                            <div className="col-md-5 col-xl-4" style={{marginTop:"25px"}}>
                            <ul className="list-unstyled footer-widget__info">
                                <li>
                                    <span className="footer-widget__info__icon"><i className="icofont-clock-time" /></span>
                                    <b> Open Hours:</b>  Mon - Sat: 9:00 EST - 17:00 EST
                                </li>
                                <li>
                                    <span className="footer-widget__info__icon"><i className="icofont-location-pin" /></span>
                                    <b> Headquarters</b> - Toronto, Canada
                                    {/* <b> Factory</b> - India | Philippines | Indonesia    */}
                                </li>
                                <li>
                                    <span className="footer-widget__info__icon"><i className="icofont-location-pin" /></span>
                                    {/* <b> Headquarters</b> - Toronto, Canada  */}
                                    <b> Factory</b> - India | Philippines | Indonesia
                                </li>
                                <li>
                                    <span className="footer-widget__info__icon"><i className="icofont-phone" /></span>
                                    <b> Toll-free</b> -  1-888-805-PEAT(7328)
                                </li>
                            </ul>{/* /.list-unstyled */}
                            </div>
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>{/* /.main-footer__top */}
                <div className="main-footer__bottom">
                    <div className="container">
                        <div className="main-footer__bottom__inner">
                            <p className="main-footer__copyright">
                                © Copyright <span className="dynamic-year" /> by Garden Grow Inc.
                            </p>
                        </div>{/* /.main-footer__inner */}
                    </div>{/* /.container */}
                </div>{/* /.main-footer__bottom */}

            </footer>{/* /.main-footer */}
        </>
    )
}

export default Footer;