import React, { useEffect } from "react";
import Head from "../header";
import Footer from "../footer";
import Slider from "react-slick"; // Ensure you have react-slick installed


function CoirDoorMats(){

    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
  <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produc-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produc-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produc-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produc-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produc-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produc-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produc-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produc-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produc-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Coir Door Mats</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Garden Grow’s coir door mats are durable, aesthetically pleasing, and crafted from natural coir Fibres. They provide a stylish and functional solution for both indoor and outdoor entrances.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Durability: Coir Fibres are strong and can withstand heavy foot traffic.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Water Resistance: Naturally resistant to mold and mildew, making it ideal for all weather conditions.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Eco-Friendly: Biodegradable and made from natural materials.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Easy Maintenance: Traps dirt effectively, easy to shake or brush clean.

</li>
                                
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                     Made from durable coir Fibres, our door mats offer a practical and stylish solution for keeping dirt out of your home or office. They’re designed to endure heavy foot traffic while maintaining their aesthetic appeal.
	Benefits: Coir mats are resistant to mold and mildew, ensuring durability and easy maintenance. They also provide excellent scraping action, removing dirt and debris from shoes.
		Applications: Ideal for both residential and commercial entrances, adding a natural and rustic touch while keeping interiors clean.


                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Sold as an eco-friendly home and garden product.</li>
                            <li><span className="icon-check-mark" /><h6>Home Use:</h6> Ideal for indoor and outdoor entrances, patios, and mudrooms.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Multiple sizes to accommodate various entryways.</p>
                        <h3 className="product-details__description__title">How to Use Coir Door Mats:</h3>
                        <ul>
                            <li><h6>At Your Doorstep:</h6> Place the coir door mat at your home or office entrance to trap dirt and moisture from shoes, preventing it from entering your living or working space.</li>
                            <li><h6>For Outdoor Areas:</h6> Coir mats are perfect for outdoor use as they can withstand the elements. Place them outside to protect your indoor floors from dirt and debris.</li>
                            <li><h6>Cleaning:</h6> Shake the mat out regularly to remove dust, dirt, and leaves. For deep cleaning, use a vacuum cleaner or hose it down, allowing it to dry before placing it back.</li>
                            <li><h6>For Décor:</h6> Use coir mats with personalized designs or messages for a welcoming look at the front door. They make for great functional and decorative pieces.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}
export default CoirDoorMats;

