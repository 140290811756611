import React from "react";
import Head from "../Layout/header";
import Footer from "../Layout/footer";
import Resourcebody from "../Layout/Resource for Growers/resourcebody";


function Resource() {
    return (
      <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <Resourcebody/>
        <Footer/>
      </>
    )
}

export default Resource;
