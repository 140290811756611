import React from "react";
import Head from "../Layout/header";
import Footer from "../Layout/footer";
import Check from "../Layout/checkout/checkoutbody";


function Checkout(){



    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <Check/>
        <Footer/>
        </>
    )
}

export default Checkout;