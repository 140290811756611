import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/Home';
import About from './components/Aboutus';
import Coco from './components/Productcoco';
import Process from './components/Process';
import Faqmain from './components/Faq';
import Booking from './components/Booking';
import Checkout from './components/checkout';
import ScrollToTop from './scroll';
import Resource from './components/Resource';
import Why from './components/why';
import Mainsignup from './components/mainsignup';
import AdminDashboard from './components/admin/admin';
import ProductManagement from './components/admin/createproduct';
import Cocopeat from './Layout/product/cocopeat';
import Cocofibre from './Layout/product/cocofibre';
import CocoMulch from './Layout/Products/CocoMulch';
import GrowBags from './Layout/product/⁠Grow Bags';
import CoirGardenPots from './Layout/product/⁠CoirGardenPots';
import CoirDoorMats from './Layout/product/Coir DoorMats';
import AnimalBedding from './Layout/product/⁠AnimalBedding';
import OrderDetails from './Layout/checkout/OrderDetails';


const App = () => {

    
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/about' element={<About />} />
                <Route path='/product' element={<Coco />} />
                <Route path='/process' element={<Process/>} />
                <Route path='/faq' element={<Faqmain/>} />
                <Route path='/booking' element={<Booking/>} />
                <Route path="/checkout" element={<Checkout/>} />
                <Route path="/resource" element={<Resource/>} />
                <Route path="/why" element={<Why/>} />
                <Route path="/signup" element={<Mainsignup/>} />
                <Route path="/admin" element={<AdminDashboard/>} />
                <Route path="/create-product" element={<ProductManagement/>} />
                <Route path="/cocopeat" element={<Cocopeat/>} />
                <Route path="/cocofibre" element={<Cocofibre/>} />
                <Route path="/cocomulch" element={<CocoMulch/>} />
                <Route path="/growbags" element={<GrowBags/>} />
                <Route path="/coirgardenpots" element={<CoirGardenPots/>} />
                <Route path="/coirdoormats" element={<CoirDoorMats/>} />
                <Route path="/animalbedding" element={<AnimalBedding/>} />
                <Route path="/orders" element={<OrderDetails/>} />
            </Routes>
        </Router>
    );
};

export default App;

  