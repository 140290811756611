import React from "react";
import Head from "../Layout/header";
import Footer from "../Layout/footer";
import Processhead from "../Layout/process/processbody";
import Processbody from "../Layout/process/processbody1";



function Process(){


    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/><br/>
        <Processhead/>
        <Processbody/>
        <Footer/>
        </>
    )
}

export default Process;