import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import '../../style/checkout.css';
import axios from 'axios'; // Import Axios

const Check = () => {
    const location = useLocation();
    const { productId, productName, productPrice, productImage, selectedCurrency } = location.state || {};

    // Debugging: Log the received product details
    console.log('Received product details:', { productId, productName, productPrice, productImage, selectedCurrency });

    // Ensure productPrice is a number
    const parsedProductPrice = parseFloat(productPrice) || 0; // Convert to number, default to 0 if NaN

    const [quantity, setQuantity] = useState(1);
    const [subtotal, setSubtotal] = useState(parsedProductPrice); // Initialize subtotal with parsed productPrice
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [isShippingAddressVisible, setShippingAddressVisible] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [shippingCountry, setShippingCountry] = useState('');
    const [shippingState, setShippingState] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingStates, setShippingStates] = useState([]);
    const [shippingCities, setShippingCities] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const newSubtotal = parsedProductPrice * quantity; // Calculate subtotal based on quantity
        setSubtotal(newSubtotal);
    }, [quantity, parsedProductPrice]);

    useEffect(() => {
        // Fetch countries on component mount
        const fetchCountries = async () => {
            const countryList = await Country.getAllCountries();
            setCountries(countryList);
        };
        fetchCountries();
    }, []);

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry);
        const stateList = await State.getStatesOfCountry(selectedCountry);
        setStates(stateList);
        setCities([]); // Reset cities when country changes
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setState(selectedState);
        const cityList = await City.getCitiesOfState(country, selectedState);
        setCities(cityList);
    };

    const handleQuantityChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value > 0) {
            setQuantity(value);
        }
    };

    const handlePlaceOrder = async (e) => {
        e.preventDefault();

        // Prepare billing details
        const billingDetails = {
            firstname: document.querySelector('input[name="first_name"]').value,
            lastname: document.querySelector('input[name="last_name"]').value,
            mobileno: document.querySelector('input[name="form_phone"]').value,
            address: document.querySelector('input[name="Address"]').value,
            country: countries.find(c => c.isoCode === country)?.name || '',
            state: states.find(s => s.isoCode === state)?.name || '',
            city: cities.find(c => c.name === city)?.name || '',
            zipcode: document.querySelector('input[name="form_zip"]').value,
        };

        // Prepare shipping details only if the shipping address is visible
        const shippingDetails = isShippingAddressVisible ? {
            firstname: document.querySelector('input[name="shipping_first_name"]').value,
            lastname: document.querySelector('input[name="shipping_last_name"]').value,
            mobileno: document.querySelector('input[name="shipping_phone"]').value,
            address: document.querySelector('input[name="shipping_address"]').value,
            country: countries.find(c => c.isoCode === shippingCountry)?.name || '',
            state: shippingStates.find(s => s.isoCode === shippingState)?.name || '',
            city: shippingCities.find(c => c.name === shippingCity)?.name || '',
            zipcode: document.querySelector('input[name="shipping_zip"]').value,
        } : {};

        // Check for empty required fields in billing details
        for (const [key, value] of Object.entries(billingDetails)) {
            if (!value) {
                setMessage(`Error: ${key.replace(/_/g, ' ')} is required.`);
                window.scrollTo(0, 0); // Scroll to the top of the page
                return; // Exit the function if any required field is empty
            }
        }

        // Check for empty required fields in shipping details only if visible
        if (isShippingAddressVisible) {
            for (const [key, value] of Object.entries(shippingDetails)) {
                if (!value) {
                    setMessage(`Error: Shipping ${key.replace(/_/g, ' ')} is required.`);
                    window.scrollTo(0, 0); // Scroll to the top of the page
                    return; // Exit the function if any required field is empty
                }
            }
        }

        // Prepare order data
        const orderData = {
            pId: productId,
            pname: productName,
            price: parsedProductPrice, // Use parsed product price
            quantity: quantity,
            totalPrice: subtotal, // Use subtotal for total price
            billingAddress: billingDetails, // Include billing details
            shippingAddress: isShippingAddressVisible ? shippingDetails : {}, // Include shipping details only if visible
        };

        console.log('Order Data:', JSON.stringify(orderData, null, 2)); // Log the order data

        // Make the API call to submit the order using Axios
        try {
            const userToken = localStorage.getItem('userToken');
            const accessToken = localStorage.getItem('accessToken');
            if ( !accessToken) {
                setMessage('Error: No token provided.');
                console.log("accessToken", accessToken);
                return;
            }
            console.log("userToken", userToken);
            console.log("accessToken", accessToken);
    
    
            const response = await axios.post('https://gardengrowbackend.onrender.com/booking/createBookings', orderData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`, 
                    'Content-Type': 'application/json',
                }
            });

            console.log('Order response:', response.data);
            setMessage('Your order has been placed successfully!');

            // Scroll to the top of the page
            window.scrollTo(0, 0); // Scroll to the top of the page

            // Reset input fields and order details
            setQuantity(1);
            setSubtotal(parsedProductPrice); // Reset subtotal to the original product price
            setCountry('');
            setState('');
            setCity('');
            setShippingCountry('');
            setShippingState('');
            setShippingCity('');
            setShippingAddressVisible(false);
            setMessage(''); // Clear message after order is placed

            // Clear cities and states
            setStates([]);
            setCities([]);
            setShippingStates([]);
            setShippingCities([]);

        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                if (error.response.status === 404) {
                    setMessage('Error: The requested resource was not found.'); // Handle 404 error
                } else {
                    setMessage(error.response.data.message || 'Error placing order.');
                }
            } else if (error.request) {
                console.error('Error request:', error.request);
                setMessage('Error placing order: No response from server.');
            } else {
                console.error('Error message:', error.message);
                setMessage(`Error placing order: ${error.message}`);
            }
        }
    };

    const handleShippingAddressToggle = () => {
        setShippingAddressVisible(!isShippingAddressVisible);
    };

    const handleShippingCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setShippingCountry(selectedCountry);
        const stateList = await State.getStatesOfCountry(selectedCountry);
        setShippingStates(stateList);
        setShippingCities([]); // Reset shipping cities when country changes
    };

    const handleShippingStateChange = async (e) => {
        const selectedState = e.target.value;
        setShippingState(selectedState);
        const cityList = await City.getCitiesOfState(shippingCountry, selectedState);
        setShippingCities(cityList);
    };

    return (
        <section className="checkout-page">
            <div className="container">
                {message && <div className="order-success-message">{message}</div>}
                <div className="row">
                    {/* Billing Address */}
                    <div className="col-xl-6 col-lg-6">
                        <div className="checkout-page__billing-address">
                            <h2 className="checkout-page__billing-address__title">Billing details</h2>
                            <form className="checkout-page__form">
                                {/* First Name */}
                                <div className="checkout-page__input-box">
                                    <input type="text" name="first_name" placeholder="First name *" required />
                                </div>
                                {/* Last Name */}
                                <div className="checkout-page__input-box">
                                    <input type="text" name="last_name" placeholder="Last name *" required />
                                </div>
                                {/* Mobile Number */}
                                <div className="checkout-page__input-box">
                                    <input type="tel" name="form_phone" required placeholder="Mobile number *" />
                                </div>
                                {/* Address */}
                                <div className="checkout-page__input-box">
                                    <input type="text" name="Address" placeholder="Address *" required />
                                </div>
                                {/* Country Dropdown */}
                                <div className="checkout-page__input-box">
                                    <select name="country" value={country} onChange={handleCountryChange} required className="custom-dropdown">
                                        <option value="">Select Country *</option>
                                        {countries.map((country) => (
                                            <option key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* State Dropdown */}
                                <div className="checkout-page__input-box">
                                    <select name="state" value={state} onChange={handleStateChange} required className="custom-dropdown">
                                        <option value="">Select State *</option>
                                        {states.map((state) => (
                                            <option key={state.isoCode} value={state.isoCode}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* City Dropdown */}
                                <div className="checkout-page__input-box">
                                    <select name="city" value={city} onChange={(e) => setCity(e.target.value)} required className="custom-dropdown">
                                        <option value="">Select City *</option>
                                        {cities.map((city) => (
                                            <option key={city.name} value={city.name}>
                                                {city.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* Zip Code */}
                                <div className="checkout-page__input-box">
                                    <input name="form_zip" type="text" pattern="[0-9]*" placeholder="Zip code *" required />
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Shipping Address */}
                    <div className="col-xl-6 col-lg-6">
                        <div className="checkout-page__shipping-address">
                            <h2 className="checkout-page__shipping-address__title">
                                <input
                                    type="checkbox"
                                    name="skipper2"
                                    id="skipper3"
                                    onChange={handleShippingAddressToggle}
                                />
                                <label htmlFor="skipper3">
                                    <span></span>Shipping to a different address
                                </label>
                            </h2>
                            {isShippingAddressVisible && (
                                <form className="checkout-page__form">
                                    {/* Country Dropdown for Shipping */}
                                    <div className="checkout-page__input-box">
                                        <input type="text" name="shipping_first_name" placeholder="First name *" required />
                                    </div>
                                    <div className="checkout-page__input-box">
                                        <input type="text" name="shipping_last_name" placeholder="Last name *" required />
                                    </div>
                                    <div className="checkout-page__input-box">
                                        <input type="tel" name="shipping_phone" required placeholder="Mobile number *" />
                                    </div>
                                    <div className="checkout-page__input-box">
                                        <input type="text" name="shipping_address" placeholder="Address *" required />
                                    </div>
                                    <div className="checkout-page__input-box">
                                        <select name="shipping_country" value={shippingCountry} onChange={handleShippingCountryChange} required className="custom-dropdown">
                                            <option value="">Select Country *</option>
                                            {countries.map((country) => (
                                                <option key={country.isoCode} value={country.isoCode}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* State Dropdown for Shipping */}
                                    <div className="checkout-page__input-box">
                                        <select name="shipping_state" value={shippingState} onChange={handleShippingStateChange} required className="custom-dropdown">
                                            <option value="">Select State *</option>
                                            {shippingStates.map((state) => (
                                                <option key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* City Dropdown for Shipping */}
                                    <div className="checkout-page__input-box">
                                        <select name="shipping_city" value={shippingCity} onChange={(e) => setShippingCity(e.target.value)} required className="custom-dropdown">
                                            <option value="">Select City *</option>
                                            {shippingCities.map((city) => (
                                                <option key={city.name} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="checkout-page__input-box">
                                        <input name="shipping_zip" type="text" pattern="[0-9]*" placeholder="Zip code *" required />
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
                {/* Your Order Section */}
                <section className="checkout-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="checkout-page__billing-address">
                                    <form className="checkout-page__form">
                                        <input type="hidden" name="product_id" value={productId || ''} />
                                        <input type="hidden" name="product_image" value={productImage || ''} />
                                    </form>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="checkout-page__shipping-address">
                                    {/* Shipping form fields */}
                                </div>
                            </div>
                        </div>
                        <div className="checkout-page__your-order">
                            <h2 className="checkout-page__your-order__title">Your order</h2>
                            <h5>Product id: {productId}</h5>
                            <h5>Currency: {selectedCurrency}</h5>
                            <h5>Product Price: {parsedProductPrice > 0 ? parsedProductPrice : '0'} {selectedCurrency}</h5> {/* Display 0 if price is not set */}
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <table className="checkout-page__order-table">
                                        <thead className="order_table_head">
                                            <tr>
                                                <th>Product</th>
                                                <th className="right">Quantity</th>
                                                <th className="right">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productId ? (
                                                <tr>
                                                    <td className="pro__title">
                                                        {productName}
                                                        <br />
                                                        <img src={productImage} alt={productName} style={{ width: '50px', marginTop: '10px' }} />
                                                    </td>
                                                    <td className="pro__quantity">
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            value={quantity}
                                                            onChange={handleQuantityChange}
                                                            style={{ width: '60px' }}
                                                        />
                                                    </td>
                                                    <td className="pro__total">{subtotal} {selectedCurrency}</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td className="pro__title">No product selected</td>
                                                    <td className="pro__quantity">-</td>
                                                    <td className="pro__total"> {selectedCurrency}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="pro__title">Subtotal</td>
                                                <td className="pro__price" colSpan="2">{subtotal} {selectedCurrency}</td>
                                            </tr>
                                            <tr>
                                                <td className="pro__title">Total</td>
                                                <td className="pro__price" colSpan="2">{subtotal} {selectedCurrency}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-6">
                                    <div className="checkout-page__payment">
                                        <div className="checkout-page__payment__item">
                                            <h3 className="checkout-page__payment__title">
                                                Paypal payment
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="text-right d-flex justify-content-end">
                                        <button type="submit" className="alefox-btn" onClick={handlePlaceOrder}>
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            <span className="alefox-btn__item"></span>
                                            Place your Order
                                        </button>
                                        {message && <span className="order-success-message">{message}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default Check;
