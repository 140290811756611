import React from "react";


function Resourcebody() {
    return (
        <>
            <div>
                <section className="project-details">
                    <div className="container">
                        <h3 className="project-details__title">Resources for Growers</h3>
                        <p>Garden Grow is committed to empowering growers across a range of environments and scales with high-quality coir-based solutions designed to support healthy growth, sustainable practices, and optimal yields. Whether you manage a commercial greenhouse, experiment with hydroponics, cultivate on a micro scale, or nurture home landscapes, our products are crafted to meet your unique needs and help your crops flourish.</p>

                        <h4 className="project-details__title-two">Greenhouses</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="assets/images/products/h-d-1-1.jpg" alt="Greenhouses" />
                        <p>Greenhouses create controlled environments that maximize growth potential, and Garden Grow’s coco products are designed to enhance their effectiveness. Our coco peat and grow bags offer superior water retention and aeration, creating the perfect balance of moisture and oxygen for healthy root development. These coir-based solutions are also naturally pest-resistant, helping growers maintain clean, disease-free crops without relying on chemicals.</p>
                        <ul>
                            <li><b>Ideal Crops:</b> Vegetables such as tomatoes, cucumbers, and peppers thrive in our coco-based mediums, as do leafy greens, herbs, and ornamental plants. Flowers like chrysanthemums and roses benefit from coco peat’s moisture-holding properties, which enhance bloom quality and prolong shelf life.</li>
                            <li><b>How It Helps:</b> Our coco products allow precise control over watering and nutrient schedules, while maintaining optimal pH levels. Customizable grow bag sizes mean our solutions can be tailored to any greenhouse setup, from high-volume vegetable production to specialty floral cultivation.</li>
                        </ul>

                        <h4 className="project-details__title-two">Hydroponics</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="assets/images/products/h-d-2-1.jpg" alt="Hydroponics" />
                        <p>Hydroponic systems require mediums that support plants without soil, allowing nutrients to reach roots efficiently. Garden Grow’s coco peat disks, grow bags, and coco fibre provide a lightweight, stable structure with high air porosity and effective drainage, keeping roots oxygenated and reducing the risk of overwatering. Our pH-neutral coco peat enables growers to monitor and adjust nutrient inputs for each crop.</p>
                        <ul>
                            <li><b>Ideal Crops:</b> Leafy greens like lettuce and spinach, herbs such as basil and cilantro, strawberries, and certain flowering plants thrive in hydroponic systems using our coco products.</li>
                            <li><b>How It Helps:</b> Our coco-based products’ high water-holding capacity reduces the need for frequent irrigation and ensures controlled nutrient dispersion. The uniformity of coco peat promotes stable root growth, making it perfect for precision-based hydroponics.</li>
                        </ul>

                        <h4 className="project-details__title-two">Micro Cultivation</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="assets/images/products/h-d-3-1.jpg" alt="Micro Cultivation" />
                        <p>Micro cultivators benefit from efficient, compact solutions that maximize output in limited spaces. Our coco mulch and customizable grow bags suit urban farms, indoor cultivators, and micro-scale operations. Coco-based mediums provide a clean, low-maintenance environment that supports strong root growth within a smaller footprint.</p>
                        <ul>
                            <li><b>Ideal Crops:</b> Herbs, microgreens, and small-batch vegetables like cherry tomatoes and baby carrots are well-suited to micro-cultivation setups.</li>
                            <li><b>How It Helps:</b> Garden Grow coco mulch retains moisture while allowing proper drainage, reducing the need for constant monitoring. Our coco fibre and grow bags support robust root expansion and nutrient retention, optimizing productivity in compact spaces.</li>
                        </ul>

                        <h4 className="project-details__title-two">Cannabis Farms</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="assets/images/products/h-d-4-1.jpg" alt="Cannabis Farms" />
                        <p>Cannabis is a sensitive crop that demands precise moisture and aeration control. Garden Grow’s coco peat and coco fibre provide this balance, supporting cannabis plants from seedling to harvest. With high water retention balanced by excellent drainage, our coco mediums ensure stable hydration and aeration, reducing root rot risks and improving yields.</p>
                        <ul>
                            <li><b>Ideal Crops:</b> Our coco products are specialized for cannabis cultivation, both indoor and outdoor. The natural pest-resistant properties of coir reduce the need for chemicals, contributing to organic growing practices.</li>
                            <li><b>How It Helps:</b> Our coco peat’s neutral pH promotes nutrient uptake, while coco fibre provides root anchoring and a favorable texture for root growth. Together, these coir-based products enable cannabis growers to achieve optimal yields with reduced water use and minimal disease risk.</li>
                        </ul>

                        <h4 className="project-details__title-two">Big Box Retail</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="" alt="Big Box Retail" />
                        <p>Garden Grow’s range includes various sizes, making them ideal for big box retailers serving diverse customers, from novice gardeners to professional landscapers. Our coir garden pots, coco peat, and animal bedding are eco-friendly and provide sustainable alternatives for garden enthusiasts and pet owners alike.</p>
                        <ul>
                            <li><b>Ideal Uses:</b> Our products support general gardening, pet care, and even DIY projects. Coir garden pots are perfect for potted plants, and our animal bedding provides a comfortable, natural bedding solution.</li>
                            <li><b>How It Helps:</b> Garden Grow’s durable coir products meet the needs of eco-conscious consumers, extending product life and reducing environmental impact, making them ideal for a retail audience focused on quality and sustainability.</li>
                        </ul>

                        <h4 className="project-details__title-two">Home Landscapes</h4>
                        <img style={{ width: "20cm", height: '13cm' }} src="assets/images/products/h-d-6-1.jpg" alt="Micro Cultivation" />

                        <p>For home gardeners and landscapers, Garden Grow’s coir-based products are easy to use, eco-friendly, and effective at promoting soil health. Our coco mulch, garden pots, and coco peat offer excellent ground cover, moisture retention, and weed suppression, making them ideal for any landscape or garden project.</p>
                        <ul>
                            <li><b>Ideal Crops:</b> Our products support a wide variety of plants, including flowering plants, shrubs, small trees, vegetables, and herbs.</li>
                            <li><b>How It Helps:</b> Coco mulch creates a natural barrier against weeds and helps retain soil moisture. Our coir garden pots make planting convenient, while coco peat enhances soil health by balancing moisture levels and encouraging beneficial soil activity, promoting healthier, more productive gardens.</li>
                        </ul>

                        <div className="project-details__bottom">

                            <h3 className="project-details__title">Coir Knowledge Hub</h3>

                            <p className="project-details__bottom__text">
                                Tips, Tricks, and Expert Insights

                                Welcome to the Garden Grow Coir Knowledge Hub—a resource center designed to empower growers of all levels. Whether you’re a home gardener, a commercial grower, or a hydroponic enthusiast, our knowledge hub is packed with tips, tricks, and guides to help you make the most of coir.

                            </p>
                        </div>
                        <ul className="project-details__text-list">
                            <li>100% Natural & Renewable: Coir is sourced from coconut husks, which are a byproduct of the coconut industry, making it a sustainable choice that helps reduce waste.</li>
                            <li>Eco-Friendly Alternative to Peat: Coir is a sustainable alternative to peat moss, which is non-renewable and harvested from delicate ecosystems.</li>
                            <li>Excellent Water Retention & Aeration: Coir’s unique structure holds moisture efficiently while maintaining proper aeration, making it ideal for hydroponics and potted plants.</li>
                            <li>Reusable and Durable: Coir can be reused for multiple growing cycles with proper treatment, offering value and sustainability over time.</li>
                        </ul>



                        {/* New Community Section */}
                        <h5 className="project-details__title">Join Garden Grow Facebook Community!</h5>
                        <p className="project-details__bottom__text">Become a part of the Garden Grow community—a place where growers, enthusiasts, and sustainable agriculture advocates come together. By joining us, you’ll gain access to:</p>
                        <ul className="project-details__text-list">
                            <li>Exclusive Discounts: Sign up for our newsletter to receive special offers, updates on new products, and tips on sustainable growing.</li>
                            <li>Early Access to New Products: Be the first to know about our latest product launches and innovations in coir-based solutions.</li>
                            <li>Community Stories and Photos: Share your gardening journey with us! Send in photos of your plants and spaces using Garden Grow products, and get featured on our social media pages.</li><br />
                            <a href="https://www.facebook.com/share/g/15embNCV1b/?mibextid=K35XfP" className="alefox-btn" target="_blank">
                                <span className="alefox-btn__item" />
                                <span className="alefox-btn__item" />
                                <span className="alefox-btn__item" />
                                <span className="alefox-btn__item" />
                                Join Our Community
                            </a>
                        </ul>

                        <h4 className="project-details__title-two">Additional Resources for Growers &nbsp;<span style={{ fontSize: "15px", color: "red" }}>(Coming Soon)</span></h4>
                        <ul>
                            <li>Growing Guides: Get comprehensive guides on how to use coco-based products in different growing setups, with best practices for watering, nutrient management, and troubleshooting.</li>
                            <li>Product Application Videos: Watch step-by-step videos on using Garden Grow products effectively.</li>
                            <li>Nutrient and pH Management Tips: Learn to maintain optimal pH and nutrient levels in hydroponics, greenhouses, and other settings.</li>
                            <li>Environmental Benefits of Coir: Discover the sustainability of coir and its impact on reducing water usage.</li>
                            <li>Crop-Specific Growing Tips: Get tips tailored for specific crops, with guidance on moisture levels, spacing, and light conditions.</li>
                            <li>Seasonal Growing Calendar: Plan your planting and harvesting with our seasonal growing calendar.</li>
                            <li>FAQ Section: Find answers to common questions about coir products.</li>
                            <li>Case Studies & Success Stories: See how other growers have successfully used Garden Grow products.</li>
                            <li>Downloadable Product Datasheets: Access detailed datasheets for each product.</li>
                            <li>Sustainable Gardening Tips: Explore eco-friendly practices that pair well with coir.</li>
                            <li>Resource Library with Industry Articles: Stay informed on trends like hydroponics and urban farming.</li>
                            <li>Interactive Product Selector Tool: Choose the best product for your needs based on your growing conditions.</li>
                            <li>Collaboration with Agricultural Experts: Access articles, webinars, and Q&A sessions with experts.</li>
                            <li>Community Forum or Grower Support Group: Join a community space where you can share tips and ask questions.</li>
                            <li>B2B and Bulk Order Resources: For larger buyers, we offer resources on bulk pricing and distribution options.</li>
                        </ul>
                        <p>For larger buyers, we offer resources on bulk pricing, distribution options, and co-branding opportunities.</p>
                        <p style={{ fontWeight: 'bold' }}>
                            Garden Grow is here to support you with the tools, knowledge, and products that help you succeed. Partner with us for a sustainable, high-performance growing experience that’s built on quality, innovation, and respect for the environment.
                        </p>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Resourcebody;