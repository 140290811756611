import React from 'react';
import { Link } from 'react-router-dom';


function Aboutbody4() {
    return (
        <>
            <div>
                <section className="project-details">
                    <div className="container">

                        <div className="project-details__image">
                            <img src="assets/images/products/p-d-1-1.jpg" alt="alefox" />
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="project-details__content">
                                    
                                    <div className="row gutter-y-30 mt50">
                                        <div className="col-md-4">
                                            <img src="assets/images/products/p-d-1-2.jpg" alt="alefox" />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="project-details__benefit">
                                                <h4 className="project-details__benefit__title">Our Promise</h4>
                                                <p className="project-details__benefit__text">
                                                    Garden Grow is more than a product supplier; we are stewards of sustainable agriculture. Every product we offer is crafted with sustainability in mind. Our coir is sourced responsibly, and our production methods focus on reducing environmental impact. By choosing Garden Grow, you join us in supporting:
                                                </p>
                                                <ul className="project-details__benefit__list">

                                                    <li><span className="icon-check-mark" />Sustainable Sourcing: We partner with eco-friendly suppliers and ensure our materials come from renewable sources.</li>
                                                    <li ><span className="icon-check-mark" />Reducing Carbon Footprint: Using coir reduces reliance on peat, helping to preserve natural habitats and reduce greenhouse gas emissions.</li>
                                                    <li><span className="icon-check-mark" />Waste Minimization: By using coir, a natural byproduct, we minimize agricultural waste and promote the use of biodegradable materials.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="project-details__bottom">

                                        <h3 className="project-details__title">Coir Knowledge Hub</h3>

                                        <p className="project-details__bottom__text">
                                            Tips, Tricks, and Expert Insights

                                            Welcome to the Garden Grow Coir Knowledge Hub—a resource center designed to empower growers of all levels. Whether you’re a home gardener, a commercial grower, or a hydroponic enthusiast, our knowledge hub is packed with tips, tricks, and guides to help you make the most of coir.

                                        </p>
                                    </div> */}
                                    {/* <ul className="project-details__text-list">
                                        <li>100% Natural & Renewable: Coir is sourced from coconut husks, which are a byproduct of the coconut industry, making it a sustainable choice that helps reduce waste.</li>
                                        <li>Eco-Friendly Alternative to Peat: Coir is a sustainable alternative to peat moss, which is non-renewable and harvested from delicate ecosystems.</li>
                                        <li>Excellent Water Retention & Aeration: Coir’s unique structure holds moisture efficiently while maintaining proper aeration, making it ideal for hydroponics and potted plants.</li>
                                        <li>Reusable and Durable: Coir can be reused for multiple growing cycles with proper treatment, offering value and sustainability over time.</li>
                                    </ul> */}

                                   

                                    {/* New Community Section */}
                                    {/* <h5 className="project-details__title">Join Garden Grow Facebook Community!</h5>
                                    <p className="project-details__bottom__text">Become a part of the Garden Grow community—a place where growers, enthusiasts, and sustainable agriculture advocates come together. By joining us, you’ll gain access to:</p>
                                    <ul className="project-details__text-list">
                                        <li>Exclusive Discounts: Sign up for our newsletter to receive special offers, updates on new products, and tips on sustainable growing.</li>
                                        <li>Early Access to New Products: Be the first to know about our latest product launches and innovations in coir-based solutions.</li>
                                        <li>Community Stories and Photos: Share your gardening journey with us! Send in photos of your plants and spaces using Garden Grow products, and get featured on our social media pages.</li><br/>
                                      <a href="https://www.facebook.com/share/g/15embNCV1b/?mibextid=K35XfP" className="alefox-btn" target="_blank">
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        Join Our Community
                                    </a>
                                    </ul> */}
                                        <br/>
                                    {/* New Industry-Specific Guides Section */}
                                   
                                    {/* New Environmental Impact Calculator Section */}
                                   

                                   
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <ul className="project-details__info">
                                    <li>Our Certifications & Standards</li>
                                    <li>Quality and Integrity at Every Step</li>
                                    <li>We believe in holding ourselves to the highest standards of quality and environmental responsibility. Garden Grow products meet rigorous industry certifications to ensure that our clients receive only the best. Our certifications include:</li>
                                    <li><span>ISO Certification:</span> Reflecting our commitment to quality management systems.</li>
                                    <li><span>Eco-Friendly Label:</span> Recognizing our sustainable sourcing and production practices.</li>
                                    <li><span>Organic Certification (where applicable):</span> Ensuring that our products are safe and suitable for organic farming practices.</li>
                                </ul>
                                {/* <div className="project-details__help">
                                    <div className="project-details__help__bg" style={{ "background-image": "url(assets/images/products/p-d-1-3.jpg)" }} />
                                    <h4 className="project-details__help__title">Book a FREE Professional Gardening Consultation!</h4>
                                    <Link to="/booking">   <a className="alefox-btn">
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        <span className="alefox-btn__item" />
                                        Book a Consultation
                                    </a></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Aboutbody4;
