import React from "react";
import Head from "../Layout/header";
import Faq from "../Layout/FAQ/faqbody";
import Footer from "../Layout/footer";
import Faqhead from "../Layout/FAQ/faqhead";



function Faqmain(){



    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/><br/>
        <Faqhead/>
        <Faq/>
        <Footer/>
        </>
    )
}

export default Faqmain;