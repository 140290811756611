import React from "react";
import AboutComponent from "../about";
import { Link } from "react-router-dom";


function About1(){


    return(
        <>
         <section className="page-header">
            <div className="page-header__shape-left" />
            <div className="page-header__shape-right" />
            <div className="page-header__bg" />
            {/* /.page-header__bg */}
            <div className="container">
              <h2 className="page-header__title bw-split-in-down">About Us</h2>
             {/* /.thm-breadcrumb list-unstyled */}
            </div>{/* /.container */}
          </section>

          <AboutComponent/>
        </>
    )
}

export default About1;