import React, { useEffect } from "react";
import Head from "../header";
import Footer from "../footer";
import Slider from "react-slick"; // Ensure you have react-slick installed

function CocoMulch(){
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pro-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pro-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pro-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pro-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/pro-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/pro-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pro-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pro-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/pro-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Coco Mulch</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Coco mulch is a coir byproduct that acts as a natural ground cover, helping regulate soil temperature, retain moisture, and suppress weeds. It’s ideal for both large-scale agricultural operations and home gardens.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Moisture Conservation: Retains soil moisture, reducing watering needs and improving water efficiency.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Weed Control: Provides a natural barrier to weeds, reducing the need for herbicides.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Temperature Regulation: Insulates soil and protects plant roots from extreme temperatures.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Nutrient Release: Breaks down over time, adding organic material to the soil and enhancing its nutrient profile.</li>
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                    		 Coco mulch is a natural, environmentally friendly ground cover that reduces evaporation and weeds while enhancing the soil structure.
		Benefits: As it decomposes, coco mulch gradually enriches the soil with organic matter, making it a sustainable alternative to traditional mulch. It helps in reducing soil temperature fluctuations and protects plant roots.
		Applications: Suitable for garden beds, around shrubs and trees, and in flower gardens to maintain soil moisture and prevent weed growth.




                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            <li><span className="icon-check-mark" /><h6>Greenhouses:</h6> Used as ground cover around potted plants to maintain stable humidity levels.</li>
                            <li><span className="icon-check-mark" /><h6>Hydroponics:</h6> Often applied around the base of hydroponic systems to prevent algae growth and control evaporation.</li>
                            <li><span className="icon-check-mark" /><h6>Micro Cultivations:</h6> Applied as a top layer in small containers to prevent soil drying and support microgreen growth.</li>
                            <li><span className="icon-check-mark" /><h6>Cannabis Farms:</h6> Used as a mulch layer to help retain moisture in cannabis growing areas and minimize temperature fluctuations.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Marketed as a natural mulch solution for garden beds and landscapes.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Popular among homeowners for garden beds, walkways, and tree bases to improve aesthetic appeal and garden health.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Available in easy-to-apply bags.</p>
                        <h3 className="product-details__description__title">How to Use Coco Mulch:</h3>
                        <p className="product-details__description__text">
                            Coco mulch is a highly efficient mulching material, great for conserving moisture and reducing weeds. Follow these steps to use it properly:
                        </p>
                        <ul>
                            <li><h6>For Garden Beds:</h6> Apply a 2-3 inch layer of coco mulch around the base of plants and over garden beds. This helps retain soil moisture, reduces weed growth, and keeps the soil temperature consistent.</li>
                            <li><h6>In Flower Beds:</h6> Use coco mulch as a decorative ground cover in flower beds. It provides a clean, neat appearance while benefiting plant growth by conserving moisture.</li>
                            <li><h6>Around Trees and Shrubs:</h6> Apply a thick layer of coco mulch around the root zone of trees and shrubs to protect the roots from extreme temperatures and improve water retention.</li>
                            <li><h6>In Raised Beds:</h6> Coco mulch is great for raised beds to minimize evaporation and keep your plants hydrated for longer periods, especially in hot climates.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}   
export default CocoMulch;