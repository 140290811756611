import React, { useEffect, useState } from "react";

function Homebody() {
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    // Check if the device is an Apple device
    const userAgent = navigator.userAgent.toLowerCase();
    setIsAppleDevice(/(iphone|ipad|ipod)/.test(userAgent));
    
    // Assuming you're using Owl Carousel, initialize it here
    if (window.$ && window.$('.alefox-owl__carousel').owlCarousel) {
      window.$('.alefox-owl__carousel').owlCarousel({
        loop: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        items: 1,
        autoplay: true,
        autoplayTimeout: 7000,
        smartSpeed: 1000,
        nav: false,
        dots: false,
        margin: 0,
      });
    }
  }, []);

  return (
    <>
      <section className="main-slider-three">
        <div className="main-slider-three__carousel alefox-owl__carousel owl-carousel">
          {/* Slider Item 1 */}
          <div className="item">
            <div className="main-slider-three__item">
              <div
                className="main-slider-three__bg"
                style={{ backgroundImage: 'url(assets/images/backgrounds/slider-3-1.jpg)' }}
              ></div>
              <div className="main-slider-three__shape-one">
                <img src="assets/images/shapes/slider-3-shape-1.png" alt="alefox" />
              </div>
              <div className="main-slider-three__shape-two">
                <img src="assets/images/shapes/slider-3-shape-2.png" alt="alefox" />
              </div>
              <div className="container">
                <div className="main-slider-three__content text-center">
                  <h5 className="main-slider-three__sub-title">Welcome to Garden Grow</h5>
                  <h2 className="main-slider-three__title" style={{ fontSize: isAppleDevice ? '3rem' : '2.5rem' }}>
                    A Producer & Exporter of Plant Growing Media
                  </h2>
                </div>
              </div>
            </div>
          </div>

          {/* Slider Item 2 */}
          <div className="item">
            <div className="main-slider-three__item">
              <div
                className="main-slider-three__bg"
                style={{ backgroundImage: 'url(assets/images/backgrounds/slider-3-2.jpg)' }}
              ></div>
              <div className="main-slider-three__shape-one">
                <img src="assets/images/shapes/slider-3-shape-1.png" alt="alefox" />
              </div>
              <div className="main-slider-three__shape-two">
                <img src="assets/images/shapes/slider-3-shape-2.png" alt="alefox" />
              </div>
              <div className="container">
                <div className="main-slider-three__content text-center">
                  <h5 className="main-slider-three__sub-title">Welcome to Garden Grow</h5>
                  <h2 className="main-slider-three__title" style={{ fontSize: isAppleDevice ? '3rem' : '2.5rem' }}>
                  A Producer & Exporter of Plant Growing Media 
                  </h2>
                  
                 
                </div>
              </div>
            </div>
          </div>

          {/* Slider Item 3 */}
          <div className="item">
            <div className="main-slider-three__item">
              <div
                className="main-slider-three__bg"
                style={{ backgroundImage: 'url(assets/images/backgrounds/slider-3-3.jpg)' }}
              ></div>
              <div className="main-slider-three__shape-one">
                <img src="assets/images/shapes/slider-3-shape-1.png" alt="alefox" />
              </div>
              <div className="main-slider-three__shape-two">
                <img src="assets/images/shapes/slider-3-shape-2.png" alt="alefox" />
              </div>
              <div className="container">
                <div className="main-slider-three__content text-center">
                  <h5 className="main-slider-three__sub-title">Welcome to Garden Grow</h5>
                  <h2 className="main-slider-three__title" style={{ fontSize: isAppleDevice ? '3rem' : '2.5rem' }}>
                  A Producer & Exporter of Plant Growing Media
                  </h2>
                  
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Homebody;
