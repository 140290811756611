import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import { FaGoogle, FaFacebook } from 'react-icons/fa'; // Import icons for Google and Facebook
import '../../style/cardbooking.css'
import { error } from 'jquery';

const clientId = "177635300855-kmlmodep0eg4qkvu59vo2uhns6vetona.apps.googleusercontent.com";
const facebookAppId = "919978323349749"; // Replace with your Facebook App ID

const Cardbooking = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [selectedPrice, setSelectedPrice] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false); // State to control modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message


  useEffect(() => {
    // Load Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: facebookAppId.trim(), // Use the trimmed Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v12.0' // Use the latest version
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://gardengrowbackend.onrender.com/products/getProduct');
        console.log('Fetched Products:', response.data.products); // Log the fetched products
        const productData = Array.isArray(response.data.products) ? response.data.products : [];
        setProducts(productData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleLoginSuccess = async (tokenResponse) => {
    console.log("Token Response:", tokenResponse); // Log the token response
    localStorage.setItem('userToken', tokenResponse.access_token); 

    // Fetch user information from Google
    try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {
                Authorization: `Bearer ${tokenResponse.access_token}`,
            },
        });

        console.log("User Info Response:", userInfoResponse.data); // Log user info response

        // Check if the response contains the expected data
        if (!userInfoResponse.data || !userInfoResponse.data.sub) {
            throw new Error("Failed to retrieve user information.");
        }

        const { sub: googleId, name, email } = userInfoResponse.data; 

        // Check if name and email are present
        if (!name || !email) {
            throw new Error("Name and email are required.");
        }
        console.log("logininfo", googleId, name, email, tokenResponse.access_token);
        const userCreationResponse = await axios.post('https://gardengrowbackend.onrender.com/user/createUserWithToken', {
            googleId,
            name,
            email,
            token: tokenResponse.access_token, // Send the token
        });
        console.log("userCreationResponse", userCreationResponse); // Log the user creation response

        // Store the access token in local storage
        if (userCreationResponse.data && userCreationResponse.data.accessToken) {
            localStorage.setItem('accessToken', userCreationResponse.data.accessToken);
            console.log("accessToken", localStorage.getItem('accessToken')); // Store the access token
        }

        // Only navigate to checkout if login is successful
        if (selectedProduct) {
            const priceString = selectedProduct[selectedPrice]; // Get the price as a string
            const price = parseFloat(priceString.replace(/[^0-9.-]+/g, '')); // Convert to number

            navigate('/checkout', { 
                state: { 
                    productId: selectedProduct._id,
                    productName: selectedProduct.pname,
                    productPrice: price, // Use the retrieved price
                    productImage: selectedProduct.image,
                    selectedCurrency: selectedPrice, // Pass the selected currency
                    accessToken: localStorage.getItem('accessToken') // Pass the access token
                } 
            });
        } else {
            navigate('/checkout'); // Redirect to checkout if no product is selected
        }
        setShowLoginModal(false); // Close the login modal after successful login
    } catch (error) {
        console.error("Error during login process:", error.response ? error.response.data : error.message);
        setErrorMessage("Login failed. Please try again."); // Set error message for UI
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Google login failed:", error); // Log the error object for debugging
    setErrorMessage(`Login failed: ${error.message || 'Please try again.'}`); // Alert user with detailed error message
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onFailure: handleLoginFailure,
    flow: 'implicit',
  });

  const handlePriceChange = (event) => {
    const selectedCurrency = event.target.value;
    console.log("Selected Currency:", selectedCurrency);
    setSelectedPrice(selectedCurrency);
  };

  const handleBuyNowClick = (product) => {
    const isLoggedIn = !!localStorage.getItem('accessToken'); // Check if user is logged in
    setSelectedProduct(product); // Store the selected product in state

    if (!selectedPrice) { // Check if currency is selected
        setErrorMessage('Please select a currency type.'); // Set error message
        window.scrollTo(0, 0); // Scroll to the top of the page
        return; // Exit the function
    }

    if (isLoggedIn) {
        // If logged in, navigate directly to checkout
        console.log("Selected Product:", product); // Log the selected product for debugging
        console.log("Selected Price Key:", selectedPrice); // Log the selected price key

        // Get the price as a string and convert to number by removing non-numeric characters
        const priceString = product[selectedPrice]; // Get the price as a string
        const price = parseFloat(priceString.replace(/[^0-9.-]+/g, '')); // Convert to number

        console.log("Retrieved Price:", price); // Log the retrieved price for debugging

        // Check if price is valid
        if (price != null && isNaN(price)) { // Check if price is NaN
            console.error(`Price not available for the selected currency: ${selectedPrice}`);
            setErrorMessage(`Price not available for the selected currency: ${selectedPrice}`); // Set error message for UI
        } else {
            navigate('/checkout', { 
                state: { 
                    productId: product._id,
                    productName: product.pname,
                    productPrice: price, // Use the retrieved price
                    productImage: product.image,
                    selectedCurrency: selectedPrice // Pass the selected currency
                } 
            });
        }
    } else {
        // Show the login modal if not logged in
        setShowLoginModal(true);
        setSelectedProduct(product); // Store the selected product to use after login
    }
  };

  const handleCloseModal = () => setShowLoginModal(false); // Close modal function

  const handleLogin = async (email, password) => {
    console.log(email, password)
    try {
        const response = await axios.post('https://gardengrowbackend.onrender.com/user/loginUser', {
            email,
            password,
        });

        console.log("Response Data:", response.data); // Check if token is in the response

        if (response.data.jwtToken) {
            localStorage.setItem('accessToken', response.data.jwtToken); // Store the token
            console.log("Token stored:", response.data.jwtToken);
            
            // Only navigate to checkout if login is successful
            if (selectedProduct) {
                const priceString = selectedProduct[selectedPrice]; // Get the price as a string
                const price = parseFloat(priceString); // Convert the price string to a number

                navigate('/checkout', { 
                    state: { 
                        productId: selectedProduct._id,
                        productName: selectedProduct.pname,
                        productPrice: price, // Use the converted price
                        productImage: selectedProduct.image,
                        selectedCurrency: selectedPrice // Pass the selected currency
                    } 
                });
            } else {
                navigate('/checkout'); // Redirect to checkout if no product is selected
            }
        } else {
            console.error("Login failed: No token received");
            setErrorMessage("Login failed. Please try again."); // Set error message for UI
        }
    } catch (error) {
        console.error("Error during login:", error.response ? error.response.data : error.message);
        setErrorMessage("Login failed. Please try again."); // Set error message for UI
    }
};



  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    const email = event.target.email.value;
    const password = event.target.password.value;
    console.log("Attempting to log in with:", email); // Log the email for debugging
    handleLogin(email, password); // Call the login function
  };

  const handleFacebookLogin = () => {
    window.FB.login(async (response) => {
        if (response.authResponse) {
            const { accessToken } = response.authResponse;
            localStorage.setItem('userToken', accessToken); // Store the token

            // Fetch user information from Facebook
            try {
                const userInfoResponse = await axios.get(`https://graph.facebook.com/me?fields=id,name,email&access_token=${accessToken}`);
                const { id: facebookId, name, email } = userInfoResponse.data;

                // Check if name and email are present
                if (!name || !email) {
                    throw new Error("Name and email are required.");
                }

                await axios.post('https://gardengrowbackend.onrender.com/user/createUserWithToken', {
                    facebookId,
                    name,
                    email,
                    token: accessToken, // Send the token
                }).catch(error => {
                    console.error("Error posting user data:", error.response ? error.response.data : error.message);
                    setErrorMessage("Failed to create user. Please try again."); // Set error message for UI
                });

                // Navigate to checkout if login is successful
                if (selectedProduct) {
                    const priceString = selectedProduct[selectedPrice]; // Get the price as a string
                    const price = parseFloat(priceString.replace(/[^0-9.-]+/g, '')); // Convert to number

                    navigate('/checkout', { 
                        state: { 
                            productId: selectedProduct._id,
                            productName: selectedProduct.pname,
                            productPrice: price, // Use the retrieved price
                            productImage: selectedProduct.image,
                            selectedCurrency: selectedPrice // Pass the selected currency
                        } 
                    });
                } else {
                    navigate('/checkout'); // Redirect to checkout if no product is selected
                }
                setShowLoginModal(false); // Close the login modal after successful login
            } catch (error) {
                console.error("Error during Facebook login process:", error.response ? error.response.data : error.message);
                setErrorMessage("Login failed. Please try again."); // Set error message for UI
            }
        } else {
            console.error("Facebook login failed:", response);
            alert('Facebook login failed. Please try again.');
        }
    }, { scope: 'email' });
  };

  return (
    <>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} {/* Display error message */}
      <section className="product-one">
        <div className="container">
          <div className="row">
            <div className="col-12 text-start mb-3">
              <select onChange={handlePriceChange} value={selectedPrice} className="form-select" style={{ width: '200px', display: 'inline-block' }}>
                <option value="">Select Currency</option>
                <option value="CAD">CAD</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
          <div className="row gutter-y-30">
            {products.map((product) => (
              <div className="col-md-6 col-lg-4 col-xl-3" key={product._id}>
                <div className="product__item shadow-sm rounded border p-3">
                  <div className="product__item__img">
                    <img src={product.image || 'placeholder.jpg'} alt={product.pname} className="img-fluid rounded" />
                    {product.onSale && (
                      <div className="product__item__flash">
                        <span className="product__item__flash__off">Sale</span>
                      </div>
                    )}
                  </div>
                  <div className="product__item__content mt-3">
                    <h4 className="product__item__title text-truncate">
                      <Link  className="text-decoration-none text-dark">{product.pname}</Link>
                    </h4>
                    <div className="product__item__description text-muted">
                      {product.pdesc}
                    </div>
                    <div className="product__item__price mt-2">
                      {selectedPrice && (
                        <div className="product__item__price-display mt-2">
                           
                        </div>
                      )}
                      {selectedPrice && (
                        <div className="product__item__price-display mt-2">
                           {product[selectedPrice] !== undefined && product[selectedPrice] !== null 
                             ? product[selectedPrice] 
                             : 'Price not available'}
                        </div>
                      )}
                    </div>
                    <div className="product__item__quantity mt-2">
                      <span className="badge bg-secondary">Quantity: {product.quantity}</span>
                    </div>
                    <button 
                      className="btn btn-success w-100 mt-3"
                      onClick={() => handleBuyNowClick(product)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={handleCloseModal} className="login-modal">
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Modal.Title>Login</Modal.Title>
            <img src="../../assets/images/logo-dark.png" alt="Logo" className="logo" style={{ height: '100px' }} /> {/* Adjust the path and size as needed */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input type="password" className="form-control" id="password" placeholder="Enter your password" required />
            </div>
            <div className="d-flex justify-content-between">
              <Button type="submit" variant="success" className="w-100 mb-2 me-2">
                Login
              </Button>
            <Link to='/signup'> <Button variant="success" onClick={() => {/* Handle sign up logic */}} className="w-100 mb-2">
                Sign Up
              </Button></Link>
            </div>
          </form>
          <Button variant="success" onClick={googleLogin} className="w-100 mb-2">
            <FaGoogle /> Login with Google
          </Button>
          <Button variant="success" onClick={handleFacebookLogin} className="w-100 mb-2">
            <FaFacebook /> Login with Facebook
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Cardbooking />
    </GoogleOAuthProvider>
  );
}
