import React, { useEffect, useRef } from 'react';

const Processbody = () => {
  const timelineRef = useRef(null);
  const timelineContainers = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            timelineRef.current.classList.add('show-line');
            timelineRef.current.classList.remove('hide-line');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    timelineContainers.current.forEach((container) => observer.observe(container));

    const handleScroll = () => {
      const timelineRect = timelineRef.current.getBoundingClientRect();
      const top = timelineRect.top;
      const bottom = timelineRect.bottom;

      if (top > window.innerHeight / 2 || bottom < window.innerHeight / 2) {
        timelineRef.current.classList.remove('show-line');
        timelineRef.current.classList.add('hide-line');
      } else {
        timelineRef.current.classList.add('show-line');
        timelineRef.current.classList.remove('hide-line');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const steps = [
    {
      icon: 'fa-seedling',
      title: 'Step 1: Sourcing Raw Materials',
      descriptions: [
        '<h5>Ethical Sourcing:</h5> We partner with local coconut farmers who follow sustainable farming practices. Garden Grow believes in fair trade and transparency, ensuring that the farmers receive fair compensation.',
        '<h6>Selection:</h6> Only premium coconut husks are chosen for production, as they provide the best quality coco peat and fibre for horticultural and agricultural purposes.'
      ]
    },
    {
      icon: 'fa-water',
      title: 'Step 2: Washing and Buffering',
      descriptions: [
        '<h6>Initial Cleaning:</h6> After collection, the husks undergo a thorough cleaning to remove impurities and any leftover coconut pulp.',
        '<h6>Buffered Washing:</h6> The husks are treated in natural water to remove excess sodium, potassium, and other soluble salts, which helps balance pH levels, creating an ideal environment for plants. This step is crucial for coco peat used in sensitive plant environments.',
        '<h6>Sustainable Water Use:</h6> We recycle the water used in washing to reduce waste and minimize our environmental footprint.'
      ]
    },
    {
      icon: 'fa-cogs',
      title: 'Step 3: Extraction and Separation',
      descriptions: [
        '<h6>Fibre Extraction:</h6> In our processing facility, specialized machinery separates the fibres from the peat. The long fibres are isolated for use in products like erosion mats, soil stabilizers, and horticultural applications.',
        '<h6>Peat Processing:</h6> The remaining material, known as pith or coco peat, is processed into fine particles and collected for further treatment. This coco peat undergoes sieving to remove larger particles, ensuring a uniform, soft texture for even water distribution.'
      ]
    },
    {
      icon: 'fa-sun',
      title: 'Step 4: Drying and Compaction',
      descriptions: [
        '<h6>Natural Sun Drying:</h6> We use solar drying methods to remove excess moisture from the peat and fibre. By harnessing sunlight, we avoid using fossil fuels, making our drying process sustainable and energy efficient.',
        '<h6>Moisture Control:</h6> During the drying phase, we monitor moisture levels closely to achieve optimal humidity levels. The drying process is key for coco peat, as it improves its storage and water retention properties.',
        '<h6>Compression:</h6> The dried coco peat is then compressed into blocks, bricks, or discs, making it easy for customers to handle, store, and transport. Coco fibre products are also shaped and baled as per their intended application.'
      ]
    },
    {
      icon: 'fa-check-circle',
      title: 'Step 5: Quality Control and Testing',
      descriptions: [
        '<h6>Rigorous Testing:</h6> Each batch undergoes stringent quality checks to confirm that moisture content, pH, and EC levels meet industry standards. We test the coco peat for its water retention, aeration, and nutrient compatibility to ensure superior plant growth.',
        '<h6>Consistency Assurance:</h6> Our quality control team ensures that all products are consistent in texture and performance, whether for home gardening, commercial farming, or landscaping applications.'
      ]
    },
    {
      icon: 'fa-box-open',
      title: 'Step 6: Packaging and Sustainability',
      descriptions: [
        '<h6>Eco-Friendly Packaging:</h6> We use biodegradable and recyclable packaging materials to minimize waste and reduce our carbon footprint.',
        '<h6>Labeling and Batch Tracking:</h6> Each package is labeled with detailed usage instructions, product specifications, and batch numbers for traceability.',
        '<h6>Final Inspection:</h6> Every product is inspected for packaging integrity to guarantee freshness and quality upon delivery.'
      ]
    },
    {
      icon: 'fa-shipping-fast',
      title: 'Step 7: Shipping and Customer Delivery',
      descriptions: [
        '<h6>Efficient Distribution:</h6> Our products are shipped efficiently to ensure they reach customers in optimal condition, whether for large-scale agricultural operations or small-scale home gardening.',
        '<h6>Customer Satisfaction:</h6> We prioritize quality and customer satisfaction at every stage, ensuring that each Garden Grow product delivers the intended benefits to customers.'
      ]
    }
  ];

  return (
    <div
      className="timeline hide-line"
      ref={timelineRef}
      style={{
        position: 'relative',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '64px 0'
      }}
    >
      {steps.map((item, index) => (
        <div
          key={index}
          className={`timeline-container ${index % 2 === 0 ? 'left' : 'right'}`}
          ref={(el) => (timelineContainers.current[index] = el)}
          style={{
            position: 'relative',
            width: '100%',
            padding: '32px 48px',
            boxSizing: 'border-box',
            opacity: 0,
            transform: 'translateY(50px)',
            transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
          }}
        >
          <a className="content-link" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
            <div
              className="content"
              style={{
                padding: '24px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                textAlign: 'left',
                color: '#205c05',
                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <span className="icon" style={{ fontSize: '24px', marginRight: '12px', color: '#205c05' }}>
                  <i className={`fas ${item.icon}`}></i>
                </span>
                <h3 style={{ margin: 0, fontSize: '1.25rem', fontWeight: 'bold' }}>{item.title}</h3>
              </div>
              {item.descriptions.map((desc, idx) => (
                <p key={idx} style={{ margin: '8px 0', lineHeight: '1.6' }} dangerouslySetInnerHTML={{ __html: desc }}></p>
              ))}
            </div>
          </a>
        </div>
      ))}

      {/* Style for the timeline line */}
      <style>{`
        .timeline::after {
          display: none;
        }
        .timeline-container {
          width: 100%;
        }
        .show {
          opacity: 1 !important;
          transform: translateY(0) !important;
        }
        @media (max-width: 768px) {
          .timeline-container {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default Processbody;
