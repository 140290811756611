import React from "react";

function WelcomeHome(){
    return(

        <>
             <section className="project-details">
                    <div className="container">
                        <h3 className="project-details__title"> 
                        Welcome to Garden Grow</h3>
                        <p>A leading provider of premium coco coir and coco fibre products for sustainable agriculture, gardening, and horticulture. Our eco-friendly solutions enhance soil quality, promote plant health, and contribute to environmental conservation. From small growers to commercial farmers, we are committed to delivering high-performance products that support your growing needs.
                            
                        </p>
                       </div>
                        
                </section>
        
        </>
    )
}

export default WelcomeHome;