import React, { useEffect } from "react";
import Head from "../header";
import Footer from "../footer";
import Slider from "react-slick"; // Ensure you have react-slick installed

function GrowBags(){
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/prod-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/prod-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/prod-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/prod-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/prod-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/prod-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/prod-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/prod-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/prod-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Grow Bags</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Garden Grow’s coir-based grow bags offer a versatile and sustainable planting solution for a wide variety of crops. These bags provide ideal conditions for root development, promoting healthier plants and higher yields.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Superior Root Development: Allows roots to grow evenly and access oxygen, preventing root binding.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Efficient Drainage: Prevents waterlogging by allowing excess water to drain while retaining necessary moisture.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Portable and Space-Saving: Lightweight and easy to move, suitable for tight spaces and urban gardens.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Reusable and Durable: High-quality materials ensure the bags can withstand multiple growing seasons.</li>
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                    		 Our premium grow bags are made from natural coir material, designed to provide optimal conditions for plant growth. They are available in customizable sizes, suitable for a variety of crops.
		Benefits: Grow bags are a space-efficient and versatile option, providing better aeration and drainage than traditional pots, helping prevent root rot and improving root health.
		Applications: Ideal for urban gardening, greenhouses, and commercial farming. Commonly used for growing tomatoes, cucumbers, strawberries, and herbs.



                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            <li><span className="icon-check-mark" /><h6>Greenhouses:</h6> Commonly used for growing vegetables and herbs, providing controlled growing conditions.</li>
                            <li><span className="icon-check-mark" /><h6>Hydroponics:</h6> Excellent as a soilless medium holder in drip irrigation hydroponic systems.</li>
                            <li><span className="icon-check-mark" /><h6>Micro Cultivations:</h6> Used for small-scale, high-density farming of herbs and leafy greens.</li>
                            <li><span className="icon-check-mark" /><h6>Cannabis Farms:</h6> Helps cannabis plants establish deep, healthy root systems, enhancing nutrient absorption and growth.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Sold as an accessible solution for urban gardeners and those with limited space.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Perfect for patios, balconies, and terraces, where soil gardening may not be possible.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Customizable based on plant type and customer requirements.</p>
                        <h3 className="product-details__description__title">How to Use Grow Bags:</h3>
                        <p className="product-details__description__text">
                            Garden Grow’s coir grow bags are perfect for growing plants, especially in confined spaces or small gardens. Here’s how to make the most of them:
                        </p>
                        <ul>
                            <li><h6>Fill with Potting Mix:</h6> Start by filling your grow bag with a high-quality potting mix. Coco peat or coco Fibre can be mixed with compost and perlite to ensure proper drainage and aeration.</li>
                            <li><h6>Planting:</h6> Simply plant your seeds, seedlings, or mature plants into the grow bag, making sure the roots are properly positioned. Ensure that your plants are spaced out appropriately for optimal growth.</li>
                            <li><h6>Watering:</h6> Water your grow bag regularly, but make sure the excess water can drain out. Coco grow bags are designed to retain moisture while preventing root rot, so don’t overwater.</li>
                            <li><h6>Placement:</h6> Grow bags can be used in balconies, terraces, or patios. Place them in a location that receives adequate sunlight and ensure good air circulation around the bags.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}   
export default GrowBags;