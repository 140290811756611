import React, { useEffect } from "react";
import Head from "../header";
import Footer from "../footer";
import Slider from "react-slick"; // Ensure you have react-slick installed

function CoirGardenPots(){
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };
    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produ-d-1-1.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produ-d-1-1.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produ-d-1-2.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produ-d-1-2.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/produ-d-1-3.jpg" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/produ-d-1-3.jpg"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produ-d-1-1.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produ-d-1-2.jpg" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/produ-d-1-3.jpg" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">Coir Garden Pots</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Biodegradable and eco-friendly, coir garden pots are ideal for starting seedlings and small plants. These pots decompose naturally, enhancing soil health as they break down.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Transplanting Ease: Can be planted directly into soil, reducing transplant shock and root disturbance.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Biodegradable: Adds organic material to the soil as it decomposes, enriching the growth environment.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Good Drainage: Allows excess water to drain easily, reducing risk of overwatering.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Lightweight and Easy to Handle: Makes them convenient for indoor and outdoor gardening.</li>
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
     Biodegradable and eco-friendly, our coir garden pots are designed for transplanting directly into soil. These pots promote root development and enhance plant health by reducing transplant shock.
Benefits: Coir pots decompose naturally in the soil, providing nutrients to the plant as they break down. They are lightweight, easy to handle, and contribute to better drainage and aeration for healthier root growth.
	Applications: Perfect for seed germination, nursery plants, and vegetable gardens. Suitable for both indoor and outdoor use, and can be planted directly into the ground or larger pots.


                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            <li><span className="icon-check-mark" /><h6>Greenhouses:</h6> Used for seed starting, allowing for easy transplantation into larger setups.</li>
                            <li><span className="icon-check-mark" /><h6>Micro Cultivations:</h6> Ideal for small-scale gardening setups, allowing plants to grow without the need for transplanting.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> A popular item among eco-conscious gardeners.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Perfect for home gardens, balcony plants, and urban gardening projects.</li>
                        </ul>
                        <h3 className="product-details__description__title">Available Sizes:</h3>
                        <p>Multiple sizes to suit various plant types and applications.</p>
                        <h3 className="product-details__description__title">How to Use Coir Garden Pots:</h3>
                        <ul>
                            <li><h6><strong>Planting:</strong></h6> Simply place your plant in the coir pot, ensuring that the roots have adequate space. Coir pots can be used with any standard potting mix, but coco peat or coir Fibre is an ideal choice for improved aeration and drainage.</li>
                            <li><h6><strong>Watering:</strong></h6> Water your plants regularly, but ensure that the coir pot has proper drainage holes to avoid over-watering. Coir pots help retain moisture without promoting root rot.</li>
                            <li><h6><strong>Transplanting:</strong></h6> When your plant is ready to be transplanted into the ground, simply plant the coir pot directly into the soil. The pot will break down naturally, providing the plant’s roots with an easy transition.</li>
                            <li><h6><strong>For Seedlings:</strong></h6> Coir pots are ideal for starting seedlings, as they promote strong root development and allow plants to be transplanted without disturbing the root system.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
        <Footer/>
        </>
    )
}               
export default CoirGardenPots;