import React from "react";
import Head from "../Layout/header";
import Footer from "../Layout/footer";
import Cardbooking from "../Layout/booking/Cardbooking";



function Booking(){

    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/>
        <Cardbooking/>
        <Footer/>
        </>
    )
}

export default Booking;