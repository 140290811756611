import React, { useEffect } from "react";
import Slider from "react-slick"; // Ensure you have react-slick installed
import Head from "../header";

import Footer from "../footer";

function Cocopeat() {
    useEffect(() => {
        // You can add any additional initialization logic here if needed
    }, []);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        dots: true,
        arrows: true,
        nextArrow: <button className="next"><i className="icon-right-arrow-two1" /></button>,
        prevArrow: <button className="prev"><i className="icon-right-arrow-two" /></button>,
    };

    const thumbSliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: true,
    };

    return (
        <>
            <Head />
            <br/><br/><br/><br/><br/>
            <section className="product-details">
                <div className="container">
                    {/* /.product-details */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="product-details__gallery">
                                <Slider {...mainSliderSettings} className="product-details__carousel alefox-slick__carousel">
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/product-d-1-1.png" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/product-d-1-1.png"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/product-d-1-2.png" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/product-d-1-2.png"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="product-details__gallery__image">
                                            <img src="assets/images/products/product-d-1-3.png" alt="alefox" />
                                            <a className="img-popup" href="assets/images/products/product-d-1-3.png"></a>{/* /.product-image */}
                                        </div>
                                    </div>
                                </Slider>
                                <Slider {...thumbSliderSettings} className="product-details__carousel-thumb alefox-slick__carousel">
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/product-d-1-1.png" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/product-d-1-2.png" alt="alefox" /></span>
                                    </div>
                                    <div className="item">
                                        <span className="product-details__meta-thumb"><img src="assets/images/products/product-d-1-3.png" alt="alefox" /></span>
                                    </div>
                                </Slider>
                            </div>
                        </div>{/* /.column */}
                        <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="product-details__content">
                                <h3 className="product-details__title">COCO PEAT</h3>{/* /.product-title */}


                                <div className="product-details__excerpt">
                                Coco peat, a finely ground material derived from coconut husks, is an eco-friendly growing medium known for its exceptional water retention and aeration properties. It serves as an alternative to traditional peat moss, offering a sustainable solution for various cultivation needs.
                                </div>{/* /.excerp-text */}
                        <h3 className="product-details__description__title">Benefits:</h3>

                                <ul className="product-details__feature">
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>High Water Retention: Holds up to 8-10 times its weight in water, helping plants maintain consistent moisture levels.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Optimal pH Balance: Neutral to slightly acidic pH (typically between 5.5-6.5), making it suitable for a wide range of plants.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Sustainable: A renewable resource that decomposes slowly, reducing environmental impact compared to traditional peat moss.</li>
                                    <li><span className="product-details__feature__icon"><i className="icofont-check-alt" /></span>Natural Aeration: Prevents soil compaction, promoting healthier root development and nutrient uptake.

</li>
                                
                                </ul>
                               
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* /.product-details */}
                    {/* /.product-description */}
                    <div className="product-details__description wow fadeInUp" data-wow-delay="300ms">
                        <h3 className="product-details__description__title">Product Description</h3>
                        <p className="product-details__description__text">
                          Coco peat is a natural, biodegradable soil additive derived from coconut husks. Its excellent water retention properties make it an ideal substitute for traditional soil conditioners and potting mixes.
		 Coco peat retains moisture for longer periods, reducing the frequency of watering and helping plants thrive in both indoor and outdoor settings. It provides a stable, nutrient-rich medium that supports root growth.
		Available Sizes: 1kg, 5kg, and 25kg blocks.
		Applications: Suitable for potting mixes, seed starting, hydroponics, and as a soil amendment to improve water-holding capacity.

                        </p>
                        <h3 className="product-details__description__title">Applications:</h3>
                        <ul className="list-unstyled product-details__description__lists">
                            <li><span className="icon-check-mark" /><h6>Greenhouses:</h6> Ideal for mixing with potting soil, providing stable moisture and aeration for seedlings and potted plants.</li>
                            <li><span className="icon-check-mark" /><h6>Hydroponics:</h6>   Serves as a stand-alone medium in hydroponic systems, helping retain nutrients and water while allowing proper drainage.</li>
                            <li><span className="icon-check-mark" /><h6>Micro Cultivations:</h6> Perfect for growing microgreens and herbs, as it provides a consistent moisture level to encourage rapid growth.</li>
                            <li><span className="icon-check-mark" /><h6>Cannabis Farms:</h6> Supports strong root growth and high nutrient absorption, which are critical for the healthy development of cannabis plants.</li>
                            <li><span className="icon-check-mark" /><h6>Big Box Retail:</h6> Packaged in various sizes for retail customers; easily incorporated into potting mixes and gardening soil.</li>
                            <li><span className="icon-check-mark" /><h6>Home Landscaping:</h6> Used as a soil amendment in garden beds, improving moisture retention and preventing soil erosion.</li>
                        </ul>
                        <h3 className="product-details__description__title">How to Use Coco Peat:</h3>
                        <p className="product-details__description__text">
                            Coco peat is an excellent growing medium that provides superior moisture retention, aeration, and root support for plants. Here’s how to use it effectively:
                        </p>
                        <ul>
                            <li><h6><strong>Hydrate Before Use:</strong></h6> Coco peat comes in a compressed form, so it needs to be rehydrated. Simply add water to the coco peat and let it expand to its full volume. It will absorb water quickly and become soft and ready to use.</li>
                            <li><h6><strong>For Seed Starting:</strong></h6> Mix coco peat with a small amount of perlite for better aeration. Fill your seed trays or pots with the mixture, plant seeds, and water regularly to keep the medium moist.</li>
                            <li><h6><strong>In Containers or Pots:</strong></h6> Fill containers with hydrated coco peat as a base for your plants. You can mix it with other soil or use it alone depending on the plant’s needs. For large pots, ensure there’s adequate drainage to prevent waterlogging.</li>
                            <li><h6><strong>In Hydroponics:</strong></h6> Use coco peat as a growing medium in hydroponic systems by adding it to trays or containers, providing an ideal environment for nutrient uptake while offering excellent water retention.</li>
                        </ul>
                    </div>
                   
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Cocopeat;