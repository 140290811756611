import React from "react";
import Head from "../Layout/header";
import About1 from "../Layout/About/Aboutbody";
import Footer from "../Layout/footer";
import Testi from "../Layout/About/Aboutbody3";
import Aboutbody4 from "../Layout/About/Aboutbody4";
import Aboutbody5 from "../Layout/About/Aboutbody5";



function About(){



    return(
        <>
        <Head/>
        <br/><br/><br/><br/><br/><br/><br/>
        <About1/>
    
        <Aboutbody4/>
        <Footer/>
        </>
    )
}

export default About;